import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  axiosGetPjp,
  postPjpData,
  updatePjpData,
} from "../../helpers/api_helper";
import { addPjpFail, getPjpValueSuccess, updatePjpFail } from "./action";
// Calender Redux States
import { ADD_NEW_PJP, GET_PJP_VALUE, UPDATE_PJP } from "./actionTypes";

//ROUTE PLAN
const asyncGetPjp = async (authtoken, userId, fromDate, toDate) => {
  try {
    const response = await axiosGetPjp(authtoken, userId, fromDate, toDate);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetPjp({ payload: { authtoken, userId, fromDate, toDate } }) {
  try {
    const response = yield call(
      asyncGetPjp,
      authtoken,
      userId,
      fromDate,
      toDate
    );
    yield put(getPjpValueSuccess(response.Pjp));
  } catch (error) {}
}

//async
const asyncPostPjpData = async (data, authtoken) => {
  try {
    const response = await postPjpData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddPjp({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostPjpData, data, authtoken);
    if (response.status == "success") {
      toast("🦄 Pjp added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("pjp");
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addPjpFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdatePjp({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(updatePjpData, data, authtoken);

    toast("🦄 Pjp updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (response.status === 200) {
      history.push("/pjp");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updatePjpFail(error));
  }
}

function* PjpSaga() {
  yield takeEvery(GET_PJP_VALUE, workerGetPjp);
  yield takeEvery(ADD_NEW_PJP, onAddPjp);
  yield takeEvery(UPDATE_PJP, onUpdatePjp);
}

export default PjpSaga;
