import {
  ADD_INSTITUTE_FAIL,
  ADD_INSTITUTE_SUCCESS,
  GET_ALL_INSTITUTE_FAIL,
  GET_ALL_INSTITUTE_SUCCESS,
  GET_SINGLE_INSTITUTE,
  GET_SINGLE_INSTITUTE_SUCCESS,
  SEARCH_PJP_FAIL,
  SEARCH_PJP_SUCCESS,
  STORE_INSTITUTE_DATA,
  STORE_SINGLE_INSTITUTE_DATA,
  UPDATE_INSTITUTE_FAIL,
  UPDATE_INSTITUTE_SUCCESS,
  USER_ASSIGN_INSTITUTE_FAIL,
  USER_ASSIGN_INSTITUTE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  instituition: [],
  instituitieLoading: true,
  singleInstituteData: [],
  selectedInstitute: [],
  searchPjpData: [],
  assignInstitute: [],
  assignInstituteLoading: true,
  singleInstituteLoading: true,
  searchPjpLoading: true,
};
const InstituteReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_INSTITUTE_SUCCESS:
      return {
        ...state,
        instituition: action.payload,
        instituitionLoading: false,
      };
    case GET_ALL_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        instituitionLoading: false,
      };

    case GET_SINGLE_INSTITUTE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_SINGLE_INSTITUTE_SUCCESS:
      return {
        ...state,
        singleInstituteData: action.payload.data,
        singleInstituteLoading: false,
      };

    case ADD_INSTITUTE_SUCCESS:
      return {
        ...state,
        institute: [...state.institute, action.payload],
        isLoading: false,
      };

    case ADD_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STORE_INSTITUTE_DATA:
      return {
        ...state,
        editInstituteInfo: action.payload,
      };
    case UPDATE_INSTITUTE_SUCCESS:
      return {
        ...state,
        instituteData: state.institute.map((institute) =>
          institute.id.toString() === action.payload.id.toString()
            ? { institute, ...action.payload }
            : institute
        ),
        loading: false,
      };

    case UPDATE_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case STORE_SINGLE_INSTITUTE_DATA:
      return {
        ...state,
        singleInstituteData: [
          ...state.singleInstituteData,
          action.payload.data,
        ],
      };

    case USER_ASSIGN_INSTITUTE_SUCCESS:
      return {
        ...state,
        assignInstitute: [...state.assignInstitute, action.payload],
        isLoading: false,
      };

    case USER_ASSIGN_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case SEARCH_PJP_SUCCESS:
      return {
        ...state,
        searchPjpData: action.payload,
        searchPjpLoading: false,
      };
    case SEARCH_PJP_FAIL:
      return {
        ...state,
        searchPjpLoading: false,
      };

    default:
      return state;
  }
};

export default InstituteReducer;
