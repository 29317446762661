import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { deleteData, IMG_API, postData } from "../../helpers/api_helper";
import { getInstituteBySO, updateIssue } from "../../store/Issue/actions";
import { getSuperVisor } from "../../store/User/actions";

const AddInstitutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [SO, SetSO] = useState("");
  const [userId, setUserId] = useState("");
  const [institute, setInstitute] = useState("");
  const [issueType, setIssueType] = useState("");
  const [issueName, setIssueName] = useState("");
  const [forWhom, setForWhom] = useState("");
  const [whomName, setWhomName] = useState("");
  const [whomContactNumber, setWhomContactNumber] = useState("");
  const [timeOrDay, setTimeOrDay] = useState("");
  const [howLongWillItTake, setHowLongWillItTake] = useState("");
  const [others, setOthers] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { id: issueId } = useParams();

  const issueTypes = [
    { value: "Technical", name: "Technical" },
    { value: "Non-Technical", name: "Non-Technical" },
  ];
  const issueLists = [
    { value: "Issue1", name: "Issue1" },
    { value: "Issue12", name: "Issue2" },
    { value: "Others", name: "Others" },
  ];
  const forWhoms = [
    { value: "Teacher", name: "Teacher" },
    { value: "Parent", name: "Parent" },
    { value: "Student", name: "Student" },
    { value: "Admin", name: "Admin" },
    { value: "School", name: "School" },
  ];
  const timeDay = [
    { value: "Hour(s)", name: "Hour(s)" },
    { value: "Day(s)", name: "Day(s)" },
    { value: "Minute(s)", name: "Minute(s)" },
  ];
  const levels = [{ value: "Secondary", name: "Secondary" }];

  const {
    loading,
    authtoken,
    superVisor,
    superVisorLoading,
    instituteBySOData,
    instituteBySODataLoading,
    editIssueInfo,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    superVisor: state?.UserReducer?.superVisor,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    instituteBySOData: state?.IssueReducer?.instituteBySOData,
    instituteBySODataLoading: state?.IssueReducer?.instituteBySODataLoading,
    editIssueInfo: state?.IssueReducer?.editIssueInfo,
  }));
  useEffect(() => {
    dispatch(getSuperVisor(authtoken, "SO", 0, 0));
  }, []);
  //Filter
  const handleSO = (data) => {
    SetSO(data._id);
    if (!!data) {
      dispatch(getInstituteBySO(authtoken, data._id));
    } else {
      dispatch(getInstituteBySO({}));
    }
  };
  const [fieldValue, setFieldValue] = useState(editIssueInfo?.data?.image);
  const handleIssueName = (data) => {
    if (data === "Others") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);

      console.log("setIsDisabled2", isDisabled);
    }
  };
  console.log("editIssueInfo", editIssueInfo);
  //add issue
  const handleValidSubmit = (e, data) => {
    e.preventDefault();
    let obj = {};
    obj.userId = userId ? userId : data?.userId?._id;
    obj.status = "Pending";
    obj.institute = institute ? institute : data?.institute?._id;
    obj.issueType = issueType ? issueType : data.issueType;
    obj.issueName = others ? others : issueName ? issueName : data.issueName;
    obj.forWhom = forWhom ? forWhom : data.forWhom;
    obj.whomName = whomName ? whomName : data.whomName;
    obj.whomContactNumber = whomContactNumber
      ? whomContactNumber
      : data.whomContactNumber;
    obj.timeOrDay = timeOrDay ? timeOrDay : data.timeOrDay;
    obj.howLongWillItTake = howLongWillItTake
      ? howLongWillItTake
      : data.howLongWillItTake;
    obj.image = fieldValue
      ? fieldValue
      : editIssueInfo?.data?.image
      ? editIssueInfo?.data?.image
      : "";
    console.log("obj", obj);
    dispatch(updateIssue(issueId, obj, authtoken, history));
  };
  //add issue
  // image upload setFieldValue, single
  const uploadImage = async (file) => {
    const url = `institute/image-uplaod`;
    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", file.name);
    try {
      const response = await postData(formData, authtoken);

      if (response.data.image) {
        setFieldValue(response.data.image.url);
        // setFieldValue(single ? name : name + ".id", response.image._id || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const ref = useRef();
  const removeImage = async () => {
    try {
      const response = await deleteData(authtoken, fieldValue);

      if (response) {
        setFieldValue("");
        ref.current.value = "";
        toast("🦄 Image deleted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <Breadcrumb
        leftTitle="Edit issue"
        rightTitle="Dashboard"
        pageTitle="Issue / Edit issue"
      />
      <div className="add-user-main-wrap">
        <Form
          className="format-upload-form-main-area-zkjdh"
          onSubmit={(e) => handleValidSubmit(e, editIssueInfo?.data)}
        >
          <Row>
            <Col lg="6">
              <Row>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Support Officer</Form.Label>
                    <Select
                      options={superVisor?.data?.data?.users}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select..."
                      onChange={(e) => {
                        setUserId(e._id);
                        handleSO({ _id: e._id });
                      }}
                      defaultValue={superVisor?.data?.data?.users?.filter(
                        (dta) => dta._id === editIssueInfo?.data?.userId?._id
                      )}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>School / Institute</Form.Label>
                    <div className="">
                      <Select
                        options={instituteBySOData?.data?.data?.instituteList}
                        getOptionLabel={(e) => e.nameEnglish}
                        getOptionValue={(e) => e._id}
                        cacheOptions
                        onChange={(e) => setInstitute(e._id)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Issue Type</Form.Label>
                    <Select
                      options={issueTypes}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select..."
                      onChange={(e) => setIssueType(e.value)}
                      defaultValue={issueTypes?.filter(
                        (dta) => dta.value === editIssueInfo?.data?.issueType
                      )}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Issue List</Form.Label>
                    <Select
                      options={issueLists}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select..."
                      onChange={(e) => {
                        setIssueName(e.value);
                        handleIssueName(e.value);
                      }}
                      defaultValue={issueLists?.filter(
                        (dta) => dta.value === editIssueInfo?.data?.issueName
                      )}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Others</Form.Label>
                    <Input
                      disabled={isDisabled}
                      type="textarea"
                      placeholder="Type others"
                      onChange={(e) => setOthers(e.target.value)}
                      style={{ height: 70 }}
                    />
                  </Form.Group>
                </Col>{" "}
              </Row>
            </Col>

            <Col lg="6">
              <Row>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>For Whom</Form.Label>
                    <Select
                      options={forWhoms}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select..."
                      onChange={(e) => setForWhom(e.value)}
                      defaultValue={forWhoms?.filter(
                        (dta) => dta.value === editIssueInfo?.data?.forWhom
                      )}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Whom Name </Form.Label>
                    <Form.Control
                      type="Text"
                      placeholder="Type name"
                      onChange={(e) => setWhomName(e.target.value)}
                      defaultValue={editIssueInfo?.data?.whomName}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="Text"
                      placeholder="Type contact number"
                      onChange={(e) => setWhomContactNumber(e.target.value)}
                      defaultValue={editIssueInfo?.data?.whomContactNumber}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Time / Day</Form.Label>
                    <Select
                      options={timeDay}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select..."
                      onChange={(e) => {
                        setTimeOrDay(e.value);
                      }}
                      defaultValue={timeDay?.filter(
                        (dta) => dta.value === editIssueInfo?.data?.timeOrDay
                      )}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>How long will it take?</Form.Label>
                    <Form.Control
                      type="Text"
                      placeholder="Type..."
                      onChange={(e) => setHowLongWillItTake(e.target.value)}
                      defaultValue={editIssueInfo?.data?.howLongWillItTake}
                    />
                  </Form.Group>
                </Col>{" "}
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Image </Form.Label>
                    <Form.Control
                      ref={ref}
                      type="file"
                      onChange={(e) => {
                        uploadImage(e.target.files[0]);
                      }}
                    />
                    {fieldValue !== "N/A" && fieldValue !== "" ? (
                      <div
                        className="position-absolute"
                        style={{
                          width: "initial",
                          right: "273px",
                        }}
                        onClick={() => removeImage()}
                      >
                        <button
                          className="btn-sm removeShadow"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          type="button"
                        >
                          <i
                            className="bx bx-trash text-danger"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="mt-2 text-center">
                      {fieldValue !== "N/A" && fieldValue !== "" ? (
                        <img
                          src={`${IMG_API}/${fieldValue}`}
                          alt="image"
                          style={{ width: "75px", height: "75px" }}
                        />
                      ) : editIssueInfo?.data?.image !== "N/A" &&
                        editIssueInfo?.data?.image !== "" ? (
                        <img
                          src={`${IMG_API}/${editIssueInfo?.data?.image}`}
                          alt="image"
                          style={{ width: "75px", height: "75px" }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="add-user-button-area-wrap">
            <div className="create-new-format-btn-area-wrap- text-center mt-4">
              <button className="btn btn-success" type="submit">
                Update Issue
              </button>
            </div>
          </div>
        </Form>
      </div>{" "}
    </div>
  );
};

export default AddInstitutes;
