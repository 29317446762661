export const GET_ATTENDANCE = "GET_ATTENDANCE";
export const GET_ATTENDANCE_SUCCESS = "GET_ATTENDANCE_SUCCESS";
export const GET_ATTENDANCE_FAIL = "GET_ATTENDANCE_FAIL";

export const GET_SINGLE_USER_ATTENDANCE = "GET_SINGLE_USER_ATTENDANCE";
export const GET_SINGLE_USER_ATTENDANCE_SUCCESS =
  "GET_SINGLE_USER_ATTENDANCE_SUCCESS";
export const GET_SINGLE_USER_ATTENDANCE_FAIL =
  "GET_SINGLE_USER_ATTENDANCE_FAIL";

export const GET_ALL_USER_ATTENDANCE = "GET_ALL_USER_ATTENDANCE";
export const GET_ALL_USER_ATTENDANCE_SUCCESS =
  "GET_ALL_USER_ATTENDANCE_SUCCESS";
export const GET_ALL_USER_ATTENDANCE_FAIL = "GET_ALL_USER_ATTENDANCE_FAIL";
