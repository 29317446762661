import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import * as XLSX from "xlsx";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { get } from "../../helpers/api_helper";
import { getUserDetails, getUsers } from "../../store/actions";
import {
  getAttendance,
  getSingleUserAttendance,
} from "../../store/Attendance/actions";
import PrintAttendanceSummary from "./PrintAttendanceSummary";

const Attendance = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [role, setRole] = useState("SO");
  const [loading, setLoading] = useState(false);
  const [soRsId, setSoRsId] = useState("All");
  const [lineManagerId, setLineManagerId] = useState("");
  const [userId, setUserId] = useState("All");
  const [status, setStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const [allUser, setAllUser] = useState(true);
  const [singleUser, setSingleUser] = useState(false);

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const attendanceUserType = [
    {
      value: "Single User",
      name: "Single User",
    },
    {
      value: "All User",
      name: "All User",
    },
  ];

  let {
    authtoken,
    attendance,
    attendanceLoading,
    users,
    attendanceSummary,
    userDetails,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    attendance: state?.AttendanceReducer?.attendance,
    attendanceLoading: state?.AttendanceReducer?.attendanceLoading,
    users: state?.UserReducer?.users,
    attendanceSummary: state?.AttendanceReducer?.singleAttendance,
    userDetails: state?.UserReducer?.userDetails,
  }));
  useEffect(() => {
    dispatch(getUsers(authtoken, "All", 0, 0));
  }, []);

  // const kkk = [{ ...users?.data?.data?.users }];

  // kkk.splice(0, 1);

  console.log("kkk", users?.data?.data?.users);

  useEffect(() => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.toDate = currentDate;
    dispatch(
      getAttendance(
        authtoken,
        lineManagerId,
        soRsId,
        fromDate,
        handleList,
        currentPage,
        status
      )
    );
  }, [fromDate, soRsId, handleList, currentPage, status]);
  useEffect(() => {
    dispatch(getSingleUserAttendance(authtoken, fromDate, currentDate, userId));
  }, [fromDate, currentDate, userId]);
  let totalPageNumber = Math.ceil(
    attendance?.data?.data?.totalLength / handleList
  );
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleChangeDate = (e) => {
    const value = e.target.value;
    setCurrentDate(value);
  };
  const handleFromDate = (e) => {
    const value = e.target.value;
    setFromDate(value);
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
  };
  const handleUser = (value) => {
    if (value) {
      setSoRsId(value._id);
    } else {
      setSoRsId("All");
    }
  };
  const handleAttendanceUser = (data) => {
    console.log("dataaa", data._id);
    if (!!data) {
      if (data._id !== "All") {
        setSingleUser(true);
        setAllUser(false);
        setUserId(data._id);
        dispatch(getUserDetails(authtoken, data._id));
      } else {
        setAllUser(true);
        setSingleUser(false);
        setUserId(data._id);
      }
    } else {
      setSingleUser(false);
      setAllUser(true);
    }
  };
  console.log("userDetails", userDetails);
  console.log("attInfo", attendanceSummary);
  const handleExcelDownload = async () => {
    setLoading(true);
    let attendanceData = [];
    await get(
      `/attendance/date-to-date-attendance-sammary?userId=${userId}&fromDate=${fromDate}&currentDate=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${authtoken}` },
      }
    )
      .then((response) => {
        if (allUser) {
          response?.attendaceSummary?.forEach((attendance, index) => {
            let data = {};
            data.Name = attendance?.userInfo?.name;
            data.EmployeeId = attendance?.userInfo?.employeeId;
            data.Role = attendance?.userInfo?.role;
            data.LineManager = attendance?.lineManager?.name;
            data.Present = attendance?.present;
            data.Late = attendance?.late;
            data.Leave = "0";
            data.Absence =
              moment(currentDate).diff(moment(fromDate), "days") +
              1 -
              attendance?.present -
              attendance?.late;

            attendanceData.push(data);
          });
        } else {
          response?.attendaceDetails?.forEach((attendance, index) => {
            let data = {};
            data.Date = attendance?.dateid;
            data.InTime = attendance?.intime;
            data.Status = attendance?.isLate ? "Late" : "Present";
            data.Remarks = attendance?.remarks;

            attendanceData.push(data);
          });
        }
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(attendanceData);
  };
  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Attendance-summary.xlsx");
    setLoading(false);
  };

  let soRsUsers = [];
  if (users?.data?.data?.users.length > 0) {
    users?.data?.data?.users?.map((data, idx) => {
      if (data.role !== "ADMIN" && data.role !== "NCO") {
        soRsUsers.push({
          _id: data._id,
          name: data.name,
        });
      }
    });
  }

  const showData = (dataHA) => {
    console.log("Type of", typeof dataHA);
    if (Array.isArray(dataHA)) {
      dataHA.shift();
      dataHA.unshift({ _id: "All", name: "All User" });
    }
    console.log("dataHA", dataHA);
    return (
      <Select
        name="user"
        options={dataHA}
        getOptionLabel={(e) => e.name}
        getOptionValue={(e) => e.value}
        placeholder="All User"
        onChange={handleAttendanceUser}
      ></Select>
    );
  };

  return (
    <div>
      <Breadcrumb
        leftTitle="Attendance"
        rightTitle="Dashboard"
        pageTitle="Attendance Summary"
      />
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb dflex-none">
          <h4></h4>
          <div
            className="all-user-filter-search-area  jhgdbjksldjfhvbnmcknjfb dflex-none"
            style={{ justifyContent: "right !important" }}
          >
            <div className="all-user-inner-wrap dflex-none text-center">
              <div className="custom-margin-top">
                <button
                  className="btn btn-success"
                  onClick={handleExcelDownload}
                  disabled={loading}
                  style={{ marginTop: "8px", padding: "8px 0.75rem" }}
                >
                  {loading ? "Downloading..." : "Download Excel"}
                </button>
              </div>{" "}
              <div>
                <ReactToPrint content={() => ref.current}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <button
                        onClick={handlePrint}
                        style={{ marginTop: "8px", padding: "8px 0.75rem" }}
                        className="btn btn-info"
                      >
                        Print{" "}
                      </button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>{" "}
              <div
                className="single-select-field mt-2"
                style={{ width: "210px" }}
              >
                {showData(users?.data?.data?.users)}
              </div>
              <div className="single-select-field">
                <Form.Group className="to-date-form">
                  <Form.Control
                    type="date"
                    value={fromDate}
                    onChange={handleFromDate}
                  />
                </Form.Group>
              </div>
              <span className="mt-3">To</span>
              <div className="single-select-field">
                <Form.Group className="mb-3 to-date-form">
                  <Form.Control
                    type="date"
                    value={currentDate}
                    // value={moment(currentDate).format("YYYY-MM-DD")}
                    onChange={handleChangeDate}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <PrintAttendanceSummary
          ref={ref}
          attendanceSummary={attendanceSummary}
          attendanceLoading={attendanceLoading}
          singleUser={singleUser}
          allUser={allUser}
          userDetails={userDetails}
          singleUser={singleUser}
          fromDate={fromDate}
          currentDate={currentDate}
        />
      </div>
    </div>
  );
};

export default Attendance;
