import {
  ADD_INSTITUTE,
  ADD_INSTITUTE_FAIL,
  ADD_INSTITUTE_SUCCESS,
  GET_ALL_INSTITUTE,
  GET_ALL_INSTITUTE_FAIL,
  GET_ALL_INSTITUTE_SUCCESS,
  GET_SINGLE_INSTITUTE,
  GET_SINGLE_INSTITUTE_SUCCESS,
  SEARCH_PJP,
  SEARCH_PJP_FAIL,
  SEARCH_PJP_SUCCESS,
  STORE_INSTITUTE_DATA,
  UPDATE_INSTITUTE,
  UPDATE_INSTITUTE_FAIL,
  UPDATE_INSTITUTE_SUCCESS,
  USER_ASSIGN_INSTITUTE,
  USER_ASSIGN_INSTITUTE_FAIL,
  USER_ASSIGN_INSTITUTE_SUCCESS,
} from "./actionTypes";

//get single institute
export const getSingleInstitution = (authtoken, instituteId) => ({
  type: GET_SINGLE_INSTITUTE,
  payload: { authtoken, instituteId },
});

export const getSingleInstitutionSuccess = (data) => ({
  type: GET_SINGLE_INSTITUTE_SUCCESS,
  payload: { data },
});

//add institute
export const addInstitute = (data, history, authtoken) => ({
  type: ADD_INSTITUTE,
  payload: { data, history, authtoken },
});

export const addInstuteSuccess = (data) => ({
  type: ADD_INSTITUTE_SUCCESS,
  payload: data,
});

export const addInstituteFail = (error) => ({
  type: ADD_INSTITUTE_FAIL,
  payload: error,
});

//UPDATE INSTITUTE
export const storeInstituteData = (name, data) => ({
  type: STORE_INSTITUTE_DATA,
  payload: { name, data },
});

export const updateInstitute = (id, data, token, history) => ({
  type: UPDATE_INSTITUTE,
  payload: { id, data, token, history },
});

export const updateInstituteSuccess = (id, data) => ({
  type: UPDATE_INSTITUTE_SUCCESS,
  payload: { id, data },
});

export const updateInstituteFail = (error) => ({
  type: UPDATE_INSTITUTE_FAIL,
  payload: error,
});

//DIVISION
export const getInstitution = (data, history, authtoken) => ({
  type: GET_ALL_INSTITUTE,
  payload: { data, history, authtoken },
});

export const getInstitutionSuccess = (data) => ({
  type: GET_ALL_INSTITUTE_SUCCESS,
  payload: data,
});

export const getInstitutionFail = (error) => ({
  type: GET_ALL_INSTITUTE_FAIL,
  payload: error,
});

export const assignInstitute = (data, history, authtoken) => ({
  type: USER_ASSIGN_INSTITUTE,
  payload: { data, history, authtoken },
});

export const assignInstituteSuccess = (data) => ({
  type: USER_ASSIGN_INSTITUTE_SUCCESS,
  payload: data,
});

export const assignInstituteFail = (error) => ({
  type: USER_ASSIGN_INSTITUTE_FAIL,
  payload: error,
});

export const getSingleInstituteData = (data) => ({
  type: USER_ASSIGN_INSTITUTE_FAIL,
  payload: { data },
});

// SEARCH pjp
export const getSearchPjp = (authtoken, data) => ({
  type: SEARCH_PJP,
  payload: { authtoken, data },
});

export const getSearchPjpSuccess = (data, authtoken) => ({
  type: SEARCH_PJP_SUCCESS,
  payload: { data, authtoken },
});

export const getSearchPjpFail = (error) => ({
  type: SEARCH_PJP_FAIL,
  payload: error,
});
