import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { deleteData, IMG_API, postData } from "../../helpers/api_helper";
import {
  getDistrictByDivision,
  getDivisionValue,
  getParliamentaryValue,
  getUpazilaValue,
  updateInstitute,
} from "../../store/actions";
import "./users.scss";

const EditInstitute = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [addUser, setAddUser] = useState("");
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [upazila, setUpazila] = useState("");
  //const [parliamentary, setParliamentary] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  const [parliamentaryByDist, setParliamentaryByDist] = useState("");
  const [sequentialNumber, setSequentialNumber] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameBangali, setNameBangali] = useState("");
  const [eiinNumber, setEiinNumber] = useState("");
  const [address, setAddress] = useState("");
  const [management, setManagement] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [level, setLevel] = useState("");
  const [studentType, setStudentType] = useState("");
  const [totalStudent, setTotalStudent] = useState("");
  const [femaleStudent, setFemaleStudent] = useState("");
  const [totalTeacher, setTotalTeacher] = useState("");
  const [femaleTeacher, setFemaleTeacher] = useState("");
  const [sizeOfCategory, setSizeOfCategory] = useState("");
  const [headOfInstitute, setHeadOfInstitute] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternativeContactNumber, setAlternativeContactNumber] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [location, setLocation] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  //const { register, handleSubmit } = useForm();

  const managements = [
    { value: "GOVERNMENT", name: "GOVERNMENT" },
    { value: "NON-GOVERNMENT", name: "NON-GOVERNMENT" },
  ];
  const instituteTypes = [
    { value: "School", name: "School" },
    { value: "College", name: "College" },
    { value: "School & College", name: "School & College" },
  ];
  const studentTypes = [
    { value: "BOYS", name: "BOYS" },
    { value: "GIRLS", name: "GIRLS" },
    { value: "CO-EDUCATION JOINT", name: "CO-EDUCATION JOINT" },
  ];
  const levels = [{ value: "Secondary", name: "Secondary" }];

  const {
    divisionData,
    loading,
    authtoken,
    districtData,
    parliamentaryData,
    districtByDivisionData,
    upazilaData,
    editInstituteInfo,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    upazilaData: state.GeoInformationReducer.upazilaData,
    editInstituteInfo: state.InstituteReducer.editInstituteInfo,
    districtByDivisionData: state.GeoInformationReducer.districtByDivisionData,
  }));
  //division list
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);

  //upazila list
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    } else {
      dispatch(getUpazilaValue({}));
    }
  }, [upazilaByDist]);

  //Parliamentary list

  useEffect(() => {
    if (parliamentaryByDist) {
      dispatch(getParliamentaryValue(authtoken, 0, 0, parliamentaryByDist));
    } else {
      dispatch(getParliamentaryValue({}));
    }
  }, [parliamentaryByDist]);

  //filter
  const handleDivision = (data) => {
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  const handleDistrict = (value) => {
    if (!!value) {
      setUpazilaByDist(value);
      setParliamentaryByDist(value);
    } else {
      setUpazilaByDist();
      setParliamentaryByDist();
    }
  };

  //add upazila
  const onSubmit = (e, data) => {
    e.preventDefault();
    console.log("eiinNumber", data.eiinNumber);
    let obj = {};
    //obj.name = upazila ? upazila : data.name;
    obj.eiinNumber = eiinNumber ? eiinNumber : data.eiinNumber;
    obj.sequentialNumber = sequentialNumber
      ? sequentialNumber
      : data.sequentialNumber;
    obj.division = division ? division : data.division._id;
    obj.district = district ? district : data.district._id;
    obj.upazila = upazila ? upazila : data.upazila._id;
    obj.address = address ? address : data.address;
    // obj.electoralSeat = parliamentaryByDist
    //   ? parliamentaryByDist
    //   : data.electoralSeat._id;
    obj.nameEnglish = nameEnglish ? nameEnglish : data.nameEnglish;
    obj.nameBangali = nameBangali ? nameBangali : data.nameBangali;
    obj.management = management ? management : data.management;
    obj.location = location ? location : data.location;
    obj.instituteType = instituteType ? instituteType : data.instituteType;
    obj.level = level ? level : data.level;
    obj.studentType = studentType ? studentType : data.studentType;
    obj.totalStudent = totalStudent ? totalStudent : data.totalStudent;
    obj.femaleStudent = femaleStudent ? femaleStudent : data.femaleStudent;
    obj.totalTeacher = totalTeacher ? totalTeacher : data.totalTeacher;
    obj.femaleTeacher = femaleTeacher ? femaleTeacher : data.femaleTeacher;
    obj.sizeOfCategory = sizeOfCategory ? sizeOfCategory : data.sizeOfCategory;
    obj.headOfInstitute = headOfInstitute
      ? headOfInstitute
      : data.headOfInstitute;
    obj.contactNumber = contactNumber ? contactNumber : data.contactNumber;
    obj.alternativeContactNumber = alternativeContactNumber
      ? alternativeContactNumber
      : data.alternativeContactNumber;
    obj.lat = lat ? lat : data.lat;
    obj.long = long ? long : data.long;
    obj.image = fieldValue
      ? fieldValue
      : editInstituteInfo?.data?.image
      ? editInstituteInfo?.data?.image
      : "";

    console.log("objjj", obj);
    dispatch(updateInstitute(data._id, obj, authtoken, history));
  };
  //add upazila
  console.log("editInstituteInfo", editInstituteInfo);
  // image upload setFieldValue, single
  const uploadImage = async (file) => {
    const url = `institute/image-uplaod`;
    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", file.name);
    try {
      const response = await postData(formData, authtoken);

      if (response.data.image) {
        setFieldValue(response.data.image.url);
        // setFieldValue(single ? name : name + ".id", response.image._id || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const ref = useRef();
  const removeImage = async () => {
    try {
      const response = await deleteData(authtoken, fieldValue);

      if (response) {
        console.log("responseeeee", response);
        setFieldValue("");
        ref.current.value = "";
        toast("🦄 Image deleted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Breadcrumb
        leftTitle="Add new institute"
        rightTitle="Dashboard"
        pageTitle="Institute / Edit institute"
      />
      <div className="add-user-main-wrap">
        <Form className="format-upload-form-main-area-zkjdh">
          <Row>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Sequential Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Sequential Number"
                  defaultValue={editInstituteInfo?.data?.sequentialNumber}
                  onChange={(e) => setSequentialNumber(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name English</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Institute Name English"
                  onChange={(e) => setNameEnglish(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.nameEnglish}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name Bangali </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Institute Name Bangali"
                  onChange={(e) => setNameBangali(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.nameBangali}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>EIIN Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type EIIN Number"
                  onChange={(e) => setEiinNumber(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.eiinNumber}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Address"
                  onChange={(e) => setAddress(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.address}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Division</Form.Label>
                <Select
                  options={divisionData?.divisions}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Division"
                  isClearable
                  onChange={(e) => {
                    setDivision(e._id);
                    handleDivision({ name: e.name, _id: e._id });
                  }}
                  defaultValue={divisionData?.divisions?.filter(
                    (dta) => dta._id === editInstituteInfo?.data?.division?._id
                  )}
                ></Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>District </Form.Label>
                <Select
                  options={districtByDivisionData?.districts}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select District"
                  onChange={(e) => {
                    setDistrict(e._id);
                    handleDistrict({ name: e.name, _id: e._id });
                  }}
                  isClearable
                  defaultValue={districtByDivisionData?.districts?.filter(
                    (dta) => dta._id === editInstituteInfo?.data?.district?._id
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Upazila </Form.Label>
                <Select
                  options={upazilaData?.upazilas}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Upazila"
                  isClearable
                  onChange={(e) => {
                    setUpazila(e._id);
                  }}
                  defaultValue={upazilaData?.upazilas?.filter(
                    (dta) => dta._id === editInstituteInfo?.data?.upazila?._id
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            {/* <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Parliamentary Seat </Form.Label>
                <Select
                  options={parliamentaryData?.electoralSeats}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Parliamentary"
                  isClearable
                  onChange={(e) => {
                    setParliamentaryByDist(e._id);
                  }}
                  defaultValue={parliamentaryData?.electoralSeats?.filter(
                    (dta) =>
                      dta._id === editInstituteInfo?.data?.electoralSeat?._id
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "} */}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Management</Form.Label>
                <Select
                  options={managements}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={(e) => {
                    setManagement(e.value);
                  }}
                  defaultValue={managements?.filter(
                    (dta) => dta.value === editInstituteInfo?.data?.management
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Location </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type user name"
                  onChange={(e) => setLocation(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.location}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Type </Form.Label>
                <Select
                  options={instituteTypes}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={(e) => {
                    setInstituteType(e.value);
                  }}
                  defaultValue={instituteTypes?.filter(
                    (dta) =>
                      dta.value === editInstituteInfo?.data?.instituteType
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Level </Form.Label>
                <Select
                  options={levels}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={(e) => {
                    setLevel(e.value);
                  }}
                  defaultValue={levels?.filter(
                    (dta) => dta.value === editInstituteInfo?.data?.level
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Student Type </Form.Label>
                <Select
                  options={studentTypes}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={(e) => {
                    setStudentType(e.value);
                  }}
                  defaultValue={studentTypes?.filter(
                    (dta) => dta.value === editInstituteInfo?.data?.studentType
                  )}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Total Student</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Total Student"
                  onChange={(e) => setTotalStudent(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.totalStudent}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Female Student</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Number of Female Student"
                  onChange={(e) => setFemaleStudent(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.femaleStudent}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Total Teacher</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Total Teacher"
                  onChange={(e) => setTotalTeacher(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.femaleTeacher}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Female Teacher</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Number of Female Teacher"
                  onChange={(e) => setFemaleTeacher(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.femaleStudent}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Size Of Category</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Size Of Category"
                  onChange={(e) => setSizeOfCategory(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.sizeOfCategory}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Head Of Institute</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Head Of Institute"
                  onChange={(e) => setHeadOfInstitute(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.headOfInstitute}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Contact Number"
                  onChange={(e) => setContactNumber(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.contactNumber}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Alternative Contact Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Alternative Contact Number"
                  onChange={(e) => setAlternativeContactNumber(e.target.value)}
                  defaultValue={
                    editInstituteInfo?.data?.alternativeContactNumber
                  }
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Lat </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Lat"
                  onChange={(e) => setLat(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.lat}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Long </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Long"
                  onChange={(e) => setLong(e.target.value)}
                  defaultValue={editInstituteInfo?.data?.long}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Image </Form.Label>
                <Form.Control
                  ref={ref}
                  type="file"
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                />
                {fieldValue ? (
                  <div
                    className="position-absolute"
                    style={{
                      width: "initial",
                      right: "272px",
                    }}
                    onClick={() => removeImage()}
                  >
                    <button
                      className="btn-sm removeShadow"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      type="button"
                    >
                      <i
                        className="bx bx-trash text-danger"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                <div className="mt-3 text-center">
                  {fieldValue ? (
                    <img
                      src={`${IMG_API}/${fieldValue}`}
                      alt="image"
                      style={{ width: "75px", height: "75px" }}
                    />
                  ) : editInstituteInfo?.data?.image !== "" &&
                    editInstituteInfo?.data?.image !== "N/A" ? (
                    <img
                      src={`${IMG_API}/${editInstituteInfo?.data?.image}`}
                      alt="image"
                      style={{ width: "75px", height: "75px" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <div className="add-user-button-area-wrap">
            <div className="create-new-format-btn-area-wrap- text-end">
              <button
                className="btn btn-success"
                type="submit"
                onClick={(e) => onSubmit(e, editInstituteInfo?.data)}
              >
                Update Institute
              </button>
            </div>
          </div>
        </Form>
      </div>{" "}
    </div>
  );
};

export default EditInstitute;
