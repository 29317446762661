import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { IMG_API, post } from "../../helpers/api_helper";
import {
  getIssue,
  getIssueDetails,
  postRemarks,
  storeIssueData,
} from "../../store/Issue/actions";
import Pagination from "../Common/Pagination";

const IssueListView = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [remarks, setRemarks] = useState("");
  const [issueId, setIssueId] = useState("");
  const [openSecond, setOpenSecond] = useState(false);
  const [confirmView, setConfirmView] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [issueStartDate, setIssueStartDate] = useState("All");
  const [issueEndDate, setIssueEndDate] = useState("All");
  const [SO, SetSO] = useState("");
  const [pageRange, setPageRange] = useState(10);

  const {
    authtoken,
    issueDetails,
    issueDetailsLoading,
    issueLimitation,
    issueList,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    issueLimitation: state?.IssueReducer?.issueLimitation,
    issueDetails: state?.IssueReducer?.issueDetails,
    issueDetailsLoading: state?.IssueReducer?.issueDetailsLoading,
    issueList: state?.IssueReducer?.issueList,
  }));

  let totalLength = issueList?.data?.totalLength;
  // let totalPageNumber = Math.ceil(totalLength / issueLimitation);
  let totalPageNumber = Math.ceil(totalLength / pageRange);

  let issueData = issueList?.data?.issues;
  var objdATA = {
    userId: SO,
    institute: props?.obj?.institute,
    issueType: props?.obj?.issueType,
    fromDate: props?.obj?.fromDate,
    toDate: props?.obj?.toDate,
    status: props?.obj?.status,
    limit: pageRange,
    pageNo: currentPage,
  };
  useEffect(() => {
    dispatch(getIssue(objdATA, history, authtoken));
  }, [
    SO,
    objdATA?.institute,
    objdATA?.issueType,
    objdATA?.fromDate,
    objdATA?.toDate,
    objdATA?.status,
    pageRange,
    currentPage,
  ]);
  const handleIssueDetails = (data) => {
    setOpenSecond(true);

    dispatch(getIssueDetails(authtoken, data._id));
  };
  const handleEditIssue = (data) => {
    dispatch(storeIssueData("singleIssue", data));
    history.push("/edit-issue/" + data._id);
  };

  const handleRemarks = (data) => {
    setConfirmView(true);
    setIssueId(data._id);
  };
  async function handleSubmit(event, errors, values) {
    event.preventDefault();
    let obj = {};
    obj.issueId = issueId;
    obj.status = "Solved";
    obj.remarks = remarks;

    dispatch(postRemarks(obj, history, authtoken, props.obj));
    setConfirmView(false);
  }

  const handleExcelDownload = async () => {
    setLoading(true);
    let issueData = [];

    await post(`/issue/issue-list`, props.obj2, {
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then((response) => {
        response?.issues?.forEach((issue, index) => {
          let data = {};
          data.InstituteName = issue?.institute?.nameEnglish;
          data.SupportOfficer = issue?.userId?.name;
          data.RS = issue?.userId?.linemanager?.name;
          data.IssueType = issue?.issueType;
          data.Issue = issue?.issueName;
          data.IssueCreateDate = issue?.issueCreatedDate;
          data.Status = issue?.status;
          issueData.push(data);
        });
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(issueData);
  };
  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Issue.xlsx");
    setLoading(false);
  };
  const handlePageRange = (e) => {
    setPageRange(e.target.value);
  };

  return (
    <div>
      <Modal
        open={openSecond}
        onClose={() => setOpenSecond(false)}
        center
        classNames={{
          overlay: "allInstituteOverlay",
          modal: "allInstituteModal",
        }}
      >
        <div className="issue-list-popup-wrap">
          <div className="institute-list-title-main text-center mb-5">
            <h5>Issue Details</h5>
          </div>
          {issueDetailsLoading ? (
            <div className="text-center mt-5 pt-5">
              <p>Loading</p>
            </div>
          ) : (
            <Row>
              <Col className="col-md-5 offset-md-4 text-center">
                {issueDetails?.data?.data?.issues?.image !== "N/A" &&
                issueDetails?.data?.data?.issues?.image !== "" ? (
                  <img
                    src={`${IMG_API}/${issueDetails?.data?.data?.issues?.image}`}
                    alt="image"
                    // style={{ width: "350px", height: "220px" }}
                    className="mb-5 text-center img_size responsive_img_size"
                  />
                ) : (
                  <></>
                )}
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Institute Name </Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.institute?.nameEnglish}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Upazila</Form.Label>
                  <span>:</span>
                  <span>
                    {" "}
                    {issueDetails?.data?.data?.issues?.institute?.upazila?.name}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                  <span>:</span>
                  <span>
                    {" "}
                    {
                      issueDetails?.data?.data?.issues?.institute?.district
                        ?.name
                    }
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Division</Form.Label>
                  <span>:</span>
                  <span>
                    {" "}
                    {
                      issueDetails?.data?.data?.issues?.institute?.division
                        ?.name
                    }
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue</Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.issueName}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue Type</Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.issueType}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue Create Date </Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.issueCreatedDate}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>For Whom</Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.forWhom}</span>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Whom Name</Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.whomName}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Whom Contact Number</Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.whomContactNumber}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Time or Day</Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.timeOrDay}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>How Long will it take</Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.howLongWillItTake}
                  </span>
                  <span>{issueDetails?.data?.data?.issues?.timeOrDay}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue Created By </Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.userId?.name}</span>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue Solved By</Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.issueSolvedUser?.name}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Issue Solved Date</Form.Label>
                  <span>:</span>
                  <span>
                    {issueDetails?.data?.data?.issues?.issueSolvedDate}
                  </span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Remarks </Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.remarks}</span>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Status </Form.Label>
                  <span>:</span>
                  <span>{issueDetails?.data?.data?.issues?.status}</span>
                </Form.Group>
              </Col>{" "}
            </Row>
          )}
        </div>
      </Modal>
      <Modal
        open={confirmView}
        onClose={() => setConfirmView(false)}
        center
        classNames={{
          overlay: "allInstituteOverlay",
          modal: "allInstituteModal-kjgfhbj issue_modal",
        }}
      >
        <div className="issue-list-popup-wrap--jvnd">
          <div className="issue-list-title-main text-center mb-5">
            {/* <h3> Issue Details</h3> */}
          </div>
          <Row>
            <Form onSubmit={handleSubmit}>
              <Col lg={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="text-center mb-4">
                    <h5>Issue Solving</h5>
                  </div>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Button
                    variant="warning"
                    className="popup-form-submit mt-3"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Col>{" "}
            </Form>
          </Row>
        </div>
      </Modal>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Issue List</h4>
          <div className="all-user-inner-wrap">
            <div className="filter-box add-division-btn">
              <button
                className="btn btn-success"
                onClick={handleExcelDownload}
                disabled={loading}
              >
                {loading ? "Downloading..." : "Download Excel"}
              </button>
            </div>
            <div className="filter-box">
              <select className="form-select m-auto" onChange={handlePageRange}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>{" "}
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Institute Name </th>
                <th>Support Officer</th>
                <th>RS</th>
                <th>Issue Type</th>
                <th>Issue</th>

                <th>Issue Create Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {(issueData || []).map((data, key) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        {key +
                          (currentPage == 1
                            ? 0
                            : currentPage * pageRange - pageRange) +
                          1}
                      </td>

                      <td>{data?.institute?.nameEnglish}</td>
                      <td>{data?.userId?.name}</td>
                      <td>{data?.userId?.linemanager?.name}</td>
                      <td>{data?.issueType}</td>
                      <td>{data?.issueName}</td>

                      <td>{data?.issueCreatedDate}</td>
                      <td>{data?.status}</td>
                      <td>
                        <div className="issue-list-button-main-wrap">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">Details</Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <Button
                                className="btn btn-info me-2 btn-sm text-white"
                                onClick={() => handleIssueDetails(data)}
                              >
                                <i class="fa-regular fa-eye"></i>
                              </Button>
                            </span>
                          </OverlayTrigger>
                          {data?.status === "Pending" ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">Edit</Tooltip>
                              }
                            >
                              <span className="d-inline-block me-2">
                                <Button
                                  variant="success"
                                  className="btn btn-sm"
                                  onClick={() => handleEditIssue(data)}
                                >
                                  <i class="fa-regular fa-pen-to-square"></i>
                                </Button>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <></>
                          )}
                          {data?.status === "Pending" ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Issue Solving
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  variant="warning"
                                  className="btn btn-sm"
                                  onClick={() => handleRemarks(data)}
                                >
                                  <i class="fas fa-check"></i>
                                </Button>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>{" "}
                  </tbody>
                </>
              );
            })}
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
          {/* <div className="empty-user-area">
            <p>
              Currently you have no user.{" "}
              <Link to="/create-user">create User</Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default IssueListView;
