import {
  ADD_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  GET_ALL_USER,
  GET_ALL_USER_FAIL,
  GET_ALL_USER_SUCCESS,
  GET_SUPERVISOR,
  GET_SUPERVISOR_FAIL,
  GET_SUPERVISOR_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  SEARCH_INSTITUTE,
  SEARCH_INSTITUTE_FAIL,
  SEARCH_INSTITUTE_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes";

export const addUser = (data, history, authtoken) => ({
  type: ADD_USER,
  payload: { data, history, authtoken },
});

export const addUserSuccess = (data) => ({
  type: ADD_USER_SUCCESS,
  payload: data,
});

export const addUserFail = (error) => ({
  type: ADD_USER_FAIL,
  payload: error,
});
export const getUsers = (authtoken, role, list, currentPage) => ({
  type: GET_ALL_USER,
  payload: { authtoken, role, list, currentPage },
});

export const getUsersSuccess = (data, authtoken) => ({
  type: GET_ALL_USER_SUCCESS,
  payload: { data, authtoken },
});

export const getUsersFail = (error) => ({
  type: GET_ALL_USER_FAIL,
  payload: error,
});
export const getSuperVisor = (authtoken, role, list, currentPage) => ({
  type: GET_SUPERVISOR,
  payload: { authtoken, role, list, currentPage },
});

export const getSuperVisorSuccess = (data, authtoken) => ({
  type: GET_SUPERVISOR_SUCCESS,
  payload: { data, authtoken },
});

export const getSuperVisorFail = (error) => ({
  type: GET_SUPERVISOR_FAIL,
  payload: error,
});

export const getUserDetails = (authtoken, id) => ({
  type: GET_USER_DETAILS,
  payload: { authtoken, id },
});

export const getUserDetailsSuccess = (data) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: { data },
});
export const getUserDetailsFail = (error) => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
});

//EDIT USER
export const storeUserData = (name, data, id) => ({
  type: STORE_USER_DATA,
  payload: { name, data, id },
});

export const updateUser = (
  id,
  data,
  token,
  history,
  handleList,
  currentPage
) => ({
  type: UPDATE_USER,
  payload: { id, data, token, history, handleList, currentPage },
});

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
});

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

//RESET PASSWORD
export const resetPassword = (data, token, history) => ({
  type: RESET_PASSWORD,
  payload: { data, token, history },
});

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { data },
});

export const resetPasswordFail = (error) => ({
  type: RESET_PASSWORD_FAIL,
  payload: error,
});

// SEARCH INSTITUTE
export const getSearchInstitute = (authtoken, data) => ({
  type: SEARCH_INSTITUTE,
  payload: { authtoken, data },
});

export const getSearchInstituteSuccess = (data, authtoken) => ({
  type: SEARCH_INSTITUTE_SUCCESS,
  payload: { data, authtoken },
});

export const getSearchInstituteFail = (error) => ({
  type: SEARCH_INSTITUTE_FAIL,
  payload: error,
});
