//ADD DIVISION
export const ADD_NEW_DIVISION = "ADD_NEW_DIVISION";
export const ADD_DIVISION_SUCCESS = "ADD_DIVISION_SUCCESS";
export const ADD_DIVISION_FAIL = "ADD_DIVISION_FAIL";

//GET DIVISION
export const GET_DIVISION_VALUE = "GET_DIVISION_VALUE";
export const GET_DIVISION_VALUE_SUCCESS = "GET_DIVISION_VALUE_SUCCESS";
export const GET_DIVISION_VALUE_FAIL = "GET_DIVISION_VALUE_FAIL";

//EDIT DIVISION
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const UPDATE_DIVISION_SUCCESS = "UPDATE_DIVISION_SUCCESS";
export const UPDATE_DIVISION_FAIL = "UPDATE_DIVISION_FAIL";

export const STORE_DIVISION_DATA = "STORE_DIVISION_DATA";

//ADD DISTRICT
export const ADD_NEW_DISTRICT = "ADD_NEW_DISTRICT";
export const ADD_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCCESS";
export const ADD_DISTRICT_FAIL = "ADD_DISTRICT_FAIL";

//GET DISTRICT
export const GET_DISTRICT_VALUE = "GET_DISTRICT_VALUE";
export const GET_DISTRICT_VALUE_SUCCESS = "GET_DISTRICT_VALUE_SUCCESS";
export const GET_DISTRICT_VALUE_FAIL = "GET_DISTRICT_VALUE_FAIL";

//EDIT DISTRICT
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS";
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL";

export const STORE_DISTRICT_DATA = "STORE_DISTRICT_DATA";

//ADD UPAZILA
export const ADD_NEW_UPAZILA = "ADD_NEW_UPAZILA";
export const ADD_UPAZILA_SUCCESS = "ADD_UPAZILA_SUCCESS";
export const ADD_UPAZILA_FAIL = "ADD_UPAZILA_FAIL";

//GET UPAZILA
export const GET_UPAZILA_VALUE = "GET_UPAZILA_VALUE";
export const GET_UPAZILA_VALUE_SUCCESS = "GET_UPAZILA_VALUE_SUCCESS";
export const GET_UPAZILA_VALUE_FAIL = "GET_UPAZILA_VALUE_FAIL";

//EDIT UPAZILA
export const UPDATE_UPAZILA = "UPDATE_UPAZILA";
export const UPDATE_UPAZILA_SUCCESS = "UPDATE_UPAZILA_SUCCESS";
export const UPDATE_UPAZILA_FAIL = "UPDATE_UPAZILA_FAIL";

export const STORE_UPAZILA_DATA = "STORE_UPAZILA_DATA";
//FILTER
export const GET_DISTRICT_BY_DIVISION = "GET_DISTRICT_BY_DIVISION";
export const GET_DISTRICT_BY_DIVISION_SUCCESS =
  "GET_DISTRICT_BY_DIVISION_SUCCESS";

//ADD PARLIAMENTARY
export const ADD_NEW_PARLIAMENTARY = "ADD_NEW_PARLIAMENTARY";
export const ADD_PARLIAMENTARY_SUCCESS = "ADD_PARLIAMENTARY_SUCCESS";
export const ADD_PARLIAMENTARY_FAIL = "ADD_PARLIAMENTARY_FAIL";

//GET PARLIAMENTARY
export const GET_PARLIAMENTARY_VALUE = "GET_PARLIAMENTARY_VALUE";
export const GET_PARLIAMENTARY_VALUE_SUCCESS =
  "GET_PARLIAMENTARY_VALUE_SUCCESS";
export const GET_PARLIAMENTARY_VALUE_FAIL = "GET_PARLIAMENTARY_VALUE_FAIL";

//EDIT PARLIAMENTARY
export const UPDATE_PARLIAMENTARY = "UPDATE_PARLIAMENTARY";
export const UPDATE_PARLIAMENTARY_SUCCESS = "UPDATE_PARLIAMENTARY_SUCCESS";
export const UPDATE_PARLIAMENTARY_FAIL = "UPDATE_PARLIAMENTARY_FAIL";

export const STORE_PARLIAMENTARY_DATA = "STORE_PARLIAMENTARY_DATA";
