import moment from "moment";
import { forwardRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { IMG_API } from "../../helpers/api_helper";
import CustomTable from "../Layout/CustomTable";
import NoTableData from "../Layout/NoTableData";

const tableHead = [
  "Sl. No.",
  "Name",
  "Employee Id",
  "Role",
  "Line Manager",
  "Present",
  "Late",
  "Leave",
  "Absence",
];
const tableHead1 = ["Sl. No.", "Date", "In time", "Status", "Remarks", "Image"];

const PrintAttendanceSummary = forwardRef((props, ref) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  let totalPageNumber = Math.ceil(
    props.attendance?.data?.data?.totalLength / handleList
  );
  console.log(
    "props.attendance?.data?.data?.todaygivenattandance",
    props.attendance?.data?.data?.todaygivenattandance
  );
  return (
    <div ref={ref}>
      <div className={props.allUser ? "d-block" : "d-none"}>
        <h4 className="text-center mt-4">Attendance Summary</h4>

        <div className="mb-4  text-center">
          Date :{" "}
          <span
            className="font-weight-bold"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {moment(props.fromDate).format("DD-MM-YYYY")} to{" "}
            {moment(props.currentDate).format("DD-MM-YYYY")}
          </span>
        </div>

        <CustomTable
          paginationClass="paginationContainer text-right"
          data={[1, 2, 3]}
          pageNo={totalPageNumber}
          tableHead={tableHead}
          setCurrentPage={setCurrentPage}
          // isPagination
        >
          {props.attendanceLoading ? (
            <tr style={{ width: "100%" }}>
              <div
                className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                style={{ width: "100%" }}
              >
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              </div>
            </tr>
          ) : props.attendanceSummary?.data?.data?.attendaceSummary?.length >
            0 ? (
            props.attendanceSummary?.data?.data?.attendaceSummary?.map(
              (data, key) => (
                <tr>
                  <td>
                    {" "}
                    {key +
                      (currentPage == 1
                        ? 0
                        : currentPage * handleList - handleList) +
                      1}
                  </td>
                  <td>{data?.userInfo?.name}</td>
                  <td>{data?.userInfo?.employeeId}</td>
                  <td>{data?.userInfo?.role}</td>
                  <td>{data?.lineManager?.name}</td>
                  <td>{data?.present} </td>
                  <td>{data?.late} </td>
                  <td>0 </td>
                  <td>
                    {moment(props.currentDate).diff(
                      moment(props.fromDate),
                      "days"
                    ) +
                      1 -
                      data?.present -
                      data?.late}{" "}
                  </td>
                </tr>
              )
            )
          ) : (
            <NoTableData
              colSpan="8"
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: `100%` }}
            >
              <span>No Data Available</span>
            </NoTableData>
          )}
        </CustomTable>
      </div>
      <div className={props.singleUser ? "d-block" : "d-none"}>
        <h3 className="text-center mt-4">Attendance Summary</h3>
        <div className="head_info mb-4 ms-2" style={{ fontSize: "14px" }}>
          <div className="mb-2" style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: "bold" }}>Name : </span>

            <span className="">
              {props.userDetails?.data?.data?.users?.name}
            </span>
          </div>
          <div className="mb-2">
            <span style={{ fontWeight: "bold" }}>Id : </span>
            <span className="">
              {props.userDetails?.data?.data?.users?.employeeId}
            </span>
          </div>
          <div className="mb-2">
            <span style={{ fontWeight: "bold" }}>Role : </span>
            <span className="">
              {props.userDetails?.data?.data?.users?.role}
            </span>
          </div>

          {props.userDetails?.data?.data?.users?.role !== "RS" ? (
            <div className="mb-2">
              <span style={{ fontWeight: "bold" }}> Line Manager : </span>
              <span className="">
                {props.userDetails?.data?.data?.users?.linemanager?.name}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="mb-2">
            <span style={{ fontWeight: "bold" }}> Date : </span>
            <span className="">
              {moment(props.fromDate).format("DD-MM-YYYY")} to{" "}
              {moment(props.currentDate).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>

        <CustomTable
          paginationClass="paginationContainer text-right"
          data={[1, 2, 3]}
          pageNo={totalPageNumber}
          tableHead={tableHead1}
          setCurrentPage={setCurrentPage}
          // isPagination
        >
          {props.attendanceLoading ? (
            <tr style={{ width: "100%" }}>
              <div
                className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                style={{ width: "100%" }}
              >
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              </div>
            </tr>
          ) : props.attendanceSummary?.data?.data?.attendaceDetails?.length >
            0 ? (
            props.attendanceSummary?.data?.data?.attendaceDetails?.map(
              (data, key) => (
                <tr>
                  <td>
                    {" "}
                    {key +
                      (currentPage == 1
                        ? 0
                        : currentPage * handleList - handleList) +
                      1}
                  </td>
                  <td>{data?.dateid}</td>
                  <td>{data?.intime}</td>
                  <td>{data?.isLate ? "Late" : "Present"}</td>
                  <td>{data?.remarks}</td>
                  <td>
                    {" "}
                    {data?.photo !== "N/A" ? (
                      <img
                        src={IMG_API + "/" + data?.photo}
                        alt="user_photo"
                        style={{ width: "40px", height: "40px" }}
                        className="me-2"
                      />
                    ) : (
                      <>N/A</>
                    )}
                  </td>
                </tr>
              )
            )
          ) : (
            <NoTableData
              colSpan="5"
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: `100%` }}
            >
              <span>No Data Available</span>
            </NoTableData>
          )}
        </CustomTable>
      </div>
    </div>
  );
});

export default PrintAttendanceSummary;
