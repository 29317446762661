import {
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  GET_ALL_USER_FAIL,
  GET_ALL_USER_SUCCESS,
  GET_SUPERVISOR_FAIL,
  GET_SUPERVISOR_SUCCESS,
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  SEARCH_INSTITUTE_FAIL,
  SEARCH_INSTITUTE_SUCCESS,
  STORE_USER_DATA,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "./actionTypes";
const INIT_STATE = {
  users: [],
  superVisor: [],
  userDetails: [],
  searchInstituteData: [],
  userLoading: true,
  editUserInfo: [],
  getUserLoading: true,
  superVisorLoading: true,
  userDetailsLoading: true,
  searchInstituteLoading: true,
};

const UserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        userLoading: false,
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        userLoading: false,
      };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        getUserLoading: false,
      };
    case GET_ALL_USER_FAIL:
      return {
        ...state,
        getUserLoading: false,
      };
    case SEARCH_INSTITUTE_SUCCESS:
      return {
        ...state,
        searchInstituteData: action.payload,
        searchInstituteLoading: false,
      };
    case SEARCH_INSTITUTE_FAIL:
      return {
        ...state,
        searchInstituteLoading: false,
      };
    case GET_SUPERVISOR_SUCCESS:
      return {
        ...state,
        superVisor: action.payload,
        superVisorLoading: false,
      };
    case GET_SUPERVISOR_FAIL:
      return {
        ...state,
        superVisorLoading: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userDetailsLoading: false,
      };
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        userDetailsLoading: false,
      };

    //EDIT USER

    case STORE_USER_DATA:
      return {
        ...state,
        editUserInfo: {
          ...state.editUserInfo,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userData: state.user.map((user) =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    //reset password
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordData: state.password.map((password) =>
          password.id.toString() === action.payload.id.toString()
            ? { password, ...action.payload }
            : password
        ),
        loading: false,
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
