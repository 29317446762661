import { all, fork } from "redux-saga/effects";
import AttendanceSaga from "./Attendance/saga";
import DashboardSaga from "./Dashboard/saga";
import forgetPasswordSaga from "./ForgetPassword/saga";
import GeoInformationSaga from "./GeoInformation/saga";
import InstituteSaga from "./Instituition/saga";
import IssueSaga from "./Issue/saga";
import LoginSaga from "./login/saga";
import PjpSaga from "./pjp/saga";
import { default as registrationSaga } from "./registration/saga";
import UserSaga from "./User/saga";

export default function* rootSaga() {
  // yield all([fork(recruitmentSaga)]);
  // yield all([fork(comparativeAnalysisSaga)]);
  yield all([fork(LoginSaga)]);
  // yield all([fork(AdminDashboardSaga)]);
  // yield all([fork(AdminAuthorSaga)]);
  yield all([fork(registrationSaga)]);
  // yield all([fork(CourseSaga)])
  // yield all([fork(academicAreaSaga)])
  yield all([fork(forgetPasswordSaga)]);
  yield all([fork(GeoInformationSaga)]);
  yield all([fork(UserSaga)]);
  yield all([fork(InstituteSaga)]);
  yield all([fork(IssueSaga)]);
  yield all([fork(PjpSaga)]);
  yield all([fork(AttendanceSaga)]);
  yield all([fork(DashboardSaga)]);
  //yield all([fork(updatePasswordSaga)])
}
