import { toast } from "react-toastify";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  addUserData,
  getSearchInstituteData,
  getUserData,
  getUserDetailsData,
  resetPasswordData,
  updateUserData,
} from "../../helpers/api_helper.js";
import {
  addUserFail,
  addUserSuccess,
  getSearchInstituteFail,
  getSearchInstituteSuccess,
  getSuperVisorFail,
  getSuperVisorSuccess,
  getUserDetailsFail,
  getUserDetailsSuccess,
  getUsers,
  getUsersFail,
  getUsersSuccess,
  resetPasswordFail,
  updateUserFail,
} from "./actions";
import {
  ADD_USER,
  GET_ALL_USER,
  GET_SUPERVISOR,
  GET_USER_DETAILS,
  RESET_PASSWORD,
  SEARCH_INSTITUTE,
  UPDATE_USER,
} from "./actionTypes";

function* onAddNewUser({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addUserData, data, authtoken);

    yield put(addUserSuccess(response));
    toast("🦄 User added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getUsers(authtoken, "All", 10, 1));
    history.push("/user-list");
  } catch (error) {
    if (!error.response) {
      toast("🦄 User added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(addUserFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}

function* fetchUsers({ payload: { authtoken, role, list, currentPage } }) {
  try {
    delay(500);
    const response = yield call(
      getUserData,
      authtoken,
      role,
      list,
      currentPage
    );
    yield put(getUsersSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getUsersFail(error));
    console.log("hi");
  }
}
function* fetchSuperVisor({ payload: { authtoken, role, list, currentPage } }) {
  try {
    delay(500);
    const response = yield call(
      getUserData,
      authtoken,
      role,
      list,
      currentPage
    );
    yield put(getSuperVisorSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getSuperVisorFail(error));
    console.log("hi");
  }
}

function* fetchSearchInstitute({ payload: { authtoken, data } }) {
  try {
    delay(500);
    const response = yield call(getSearchInstituteData, authtoken, data);
    yield put(getSearchInstituteSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getSearchInstituteFail(error));
    console.log("hi");
  }
}
function* fetchUserDetails({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getUserDetailsData, authtoken, id);
    yield put(getUserDetailsSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getUserDetailsFail(error));
    console.log("hi");
  }
}
//EDIT USER
function* onUpdateUser({
  payload: { id, data, token, history, handleList, currentPage },
}) {
  try {
    const response = yield call(updateUserData, id, data, token);

    toast("🦄 User updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //yield put(getUserValue(token, 1, 10));
    if (response.status === 200) {
      yield put(getUsers(token, "All", handleList, currentPage));
      history.push("/user-list");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateUserFail(error));
  }
}

//reset password
function* onResetPassword({ payload: { data, token, history } }) {
  try {
    const response = yield call(resetPasswordData, data, token);

    toast("🦄 Password updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    //yield put(getUserValue(token, 1, 10));
    // if (response.status === 200) {
    //   yield put(getUsers(token, "All", handleList, currentPage));
    //   history.push("/user-list");
    // }
  } catch (error) {
    console.log(error.response);
    yield put(resetPasswordFail(error));
  }
}

function* UserSaga() {
  yield takeEvery(ADD_USER, onAddNewUser);
  yield takeEvery(GET_ALL_USER, fetchUsers);
  yield takeEvery(GET_SUPERVISOR, fetchSuperVisor);
  yield takeEvery(GET_USER_DETAILS, fetchUserDetails);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(SEARCH_INSTITUTE, fetchSearchInstitute);
  yield takeEvery(RESET_PASSWORD, onResetPassword);
}

export default UserSaga;
