import {
  ADD_ISSUE_FAIL,
  ADD_ISSUE_SUCCESS,
  GET_INSTITUTE_BY_SO_FAIL,
  GET_INSTITUTE_BY_SO_SUCCESS,
  GET_ISSUE_DETAILS_FAIL,
  GET_ISSUE_DETAILS_SUCCESS,
  GET_ISSUE_LIST_FAIL,
  GET_ISSUE_LIST_SUCCESS,
  POST_REMARKS_FAIL,
  POST_REMARKS_SUCCESS,
  STORE_ISSUE_DATA,
  UPDATE_ISSUE_FAIL,
  UPDATE_ISSUE_SUCCESS,
  STORE_ISSUE_LIMITATION
} from "./actionTypes";
const INIT_STATE = {
  instituteBySOData: [],
  issueList: [],
  issueDetails: [],
  remarks: [],
  issueLimitation: 10,
  instituteBySODataLoading: true,
  issueListLoading: true,
  issueDetailsLoading: true,
  remarksLoading: true,
};
const IssueReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTE_BY_SO_SUCCESS:
      return {
        ...state,
        instituteBySOData: action.payload,
        instituteBySODataLoading: false,
      };
    case GET_INSTITUTE_BY_SO_FAIL:
      return {
        ...state,
        error: action.payload,
        instituteBySODataLoading: false,
      };
    case GET_ISSUE_LIST_SUCCESS:
      return {
        ...state,
        issueList: action.payload,
        issueListLoading: false,
      };
    case GET_ISSUE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        issueListLoading: false,
      };
    case GET_ISSUE_DETAILS_SUCCESS:
      return {
        ...state,
        issueDetails: action.payload,
        issueDetailsLoading: false,
      };
    case GET_ISSUE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        issueDetailsLoading: false,
      };
    case POST_REMARKS_SUCCESS:
      return {
        ...state,
        remarks: action.payload,
        remarksLoading: false,
      };
    case POST_REMARKS_FAIL:
      return {
        ...state,
        error: action.payload,
        remarksLoading: false,
      };
    // ADD ISSUE
    case ADD_ISSUE_SUCCESS:
      return {
        ...state,
        issue: [...state.issue, action.payload],
        isLoading: false,
      };

    case ADD_ISSUE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    //update issue
    case STORE_ISSUE_DATA:
      return {
        ...state,
        editIssueInfo: action.payload,
      };
    case UPDATE_ISSUE_SUCCESS:
      return {
        ...state,
        issueData: state.issue.map((issue) =>
          issue.id.toString() === action.payload.id.toString()
            ? { issue, ...action.payload }
            : issue
        ),
        loading: false,
      };

    case UPDATE_ISSUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

      case STORE_ISSUE_LIMITATION:
        return {
          ...state,
          issueLimitation: action.payload.data,
        };
    default:
      return state;
  }
};
export default IssueReducer;
