import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import DistrictView from "./DistrictView";

const District = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="District"
      />

      <DistrictView />
    </div>
  );
};

export default District;
