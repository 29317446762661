import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import AssignInstituteView from "./AssignInstituteView";

const AssignInstitute = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="User List"
      />

      <AssignInstituteView />
    </div>
  );
};

export default AssignInstitute;
