import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import UserListTop from "./UserListTop";
import UserListView from "./UserListView";

const UserList = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="User List"
      />
      {/* <UserListTop /> */}
      <UserListView />
    </div>
  );
};

export default UserList;
