import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  axiosGetDistrict,
  axiosGetDistrictByDivision,
  axiosGetDivision,
  axiosGetParliamentary,
  axiosGetUpazila,
  postDistrictData,
  postDivisiondata,
  postParliamentaryData,
  postUpazilaData,
  REACT_APP_API,
  updateDistrictData,
  updateDivisionData,
  updateParliamentaryData,
  updateUpazilaData,
} from "../../helpers/api_helper.js";
import {
  addDistrictFail,
  addDivisionFail,
  addParliamentaryFail,
  addUpazilaFail,
  getDistrictByDivisionSuccess,
  getDistrictValue,
  getDistrictValueSuccess,
  getDivisionValue,
  getDivisionValueSuccess,
  getParliamentaryValue,
  getParliamentaryValueSuccess,
  getUpazilaValue,
  getUpazilaValueSuccess,
  updateDistrictFail,
  updateDivisionFail,
  updateParliamentaryFail,
  updateUpazilaFail,
} from "./action";
// Calender Redux States
import {
  ADD_NEW_DISTRICT,
  ADD_NEW_DIVISION,
  ADD_NEW_PARLIAMENTARY,
  ADD_NEW_UPAZILA,
  GET_DISTRICT_BY_DIVISION,
  GET_DISTRICT_VALUE,
  GET_DIVISION_VALUE,
  GET_PARLIAMENTARY_VALUE,
  GET_UPAZILA_VALUE,
  UPDATE_DISTRICT,
  UPDATE_DIVISION,
  UPDATE_PARLIAMENTARY,
  UPDATE_UPAZILA,
} from "./actionTypes";

//REGION
const asyncGetDivision = async (authtoken, currentPage, pageRange) => {
  try {
    const response = await axiosGetDivision(authtoken, currentPage, pageRange);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetDivision({
  payload: { authtoken, currentPage, pageRange },
}) {
  try {
    const response = yield call(
      asyncGetDivision,
      authtoken,
      currentPage,
      pageRange
    );
    yield put(getDivisionValueSuccess(response));
  } catch (error) {}
}

//async
const asyncPostDivisionData = async (data, authtoken) => {
  try {
    const response = await postDivisiondata(data, authtoken);

    console.log("response", response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddDivision({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostDivisionData, data, authtoken);
    console.log("response", history);
    if (response.status === "success") {
      console.log("response", response.status);
      toast("🦄 Division added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(getDivisionValue(authtoken, 1, 10));
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    console.log("error", error);
    yield put(addDivisionFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdateDivision({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateDivisionData, id, data, token);
    toast("🦄 Division updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getDivisionValue(token, 1, 10));
    if (response.status === 200) {
      history.push("/division");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateDivisionFail(error));
  }
}
//DISTRICT;

const asyncGetDistrict = async (
  authtoken,
  dataRange,
  currentPage,
  distByDiv
) => {
  try {
    const url = distByDiv
      ? `${REACT_APP_API}/district/get-district-by-division/${distByDiv._id}/${currentPage}/${dataRange}`
      : `${REACT_APP_API}/district/${currentPage}/${dataRange}`;
    const response = await axiosGetDistrict(url, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetDistrict({
  payload: { authtoken, dataRange, currentPage, distByDiv },
}) {
  try {
    const response = yield call(
      asyncGetDistrict,
      authtoken,
      dataRange,
      currentPage,
      distByDiv
    );
    yield put(getDistrictValueSuccess(response));
  } catch (error) {}
}

//async
const asyncPostDistrictData = async (data, authtoken) => {
  try {
    const response = await postDistrictData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddDistrict({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostDistrictData, data, authtoken);
    if (response.status == "success") {
      toast("🦄 District added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(getDistrictValue(authtoken, 1, 10));
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addDistrictFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdateDistrict({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateDistrictData, id, data, token);
    // yield put(updateTerritorySuccess(response))
    toast("🦄 District updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getDistrictValue(token, 1, 10));
    if (response.status === 200) {
      history.push("/district");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateDistrictFail(error));
  }
}

//UPAZILA
const asyncGetUpazila = async (
  authtoken,
  dataRange,
  currentPage,
  upazilaByDist
) => {
  try {
    const url = upazilaByDist
      ? `${REACT_APP_API}/upazila/get-upazila-by-district/${upazilaByDist._id}/${currentPage}/${dataRange}`
      : `${REACT_APP_API}/upazila/${currentPage}/${dataRange}`;
    const response = await axiosGetUpazila(url, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetUpazila({
  payload: { authtoken, dataRange, currentPage, upazilaByDist },
}) {
  try {
    const response = yield call(
      asyncGetUpazila,
      authtoken,
      dataRange,
      currentPage,
      upazilaByDist
    );
    yield put(getUpazilaValueSuccess(response));
  } catch (error) {}
}
//filter
const asyncGetDistrictByDivision = async (
  authtoken,
  id,
  dataRange,
  currentPage
) => {
  try {
    const response = await axiosGetDistrictByDivision(
      authtoken,
      id,
      dataRange,
      currentPage
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetDistrictByDivision({
  payload: { authtoken, id, dataRange, currentPage },
}) {
  try {
    const response = yield call(
      asyncGetDistrictByDivision,
      authtoken,
      id,
      dataRange,
      currentPage
    );
    yield put(getDistrictByDivisionSuccess(response));
  } catch (error) {}
}

//async
const asyncPostUpazilaData = async (data, authtoken) => {
  try {
    const response = await postUpazilaData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddUpazila({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostUpazilaData, data, authtoken);

    if (response.status == "success") {
      toast("🦄 Upazila added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(getUpazilaValue(authtoken, 1, 10));
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addUpazilaFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdateUpazila({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateUpazilaData, id, data, token);

    toast("🦄 Upazila updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getUpazilaValue(token, 1, 10));
    if (response.status === 200) {
      history.push("/upazila");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateUpazilaFail(error));
  }
}

//PARLIAMENTARY
const asyncGetParliamentary = async (
  authtoken,
  dataRange,
  currentPage,
  parliamentaryByDist
) => {
  try {
    const url = parliamentaryByDist
      ? `${REACT_APP_API}/electoralSeat/get-electoral-seat-by-district/${parliamentaryByDist._id}/${currentPage}/${dataRange}`
      : `${REACT_APP_API}/electoralSeat/${currentPage}/${dataRange}`;
    const response = await axiosGetParliamentary(url, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetParliamentary({
  payload: { authtoken, dataRange, currentPage, parliamentaryByDist },
}) {
  try {
    const response = yield call(
      asyncGetParliamentary,
      authtoken,
      dataRange,
      currentPage,
      parliamentaryByDist
    );
    yield put(getParliamentaryValueSuccess(response));
  } catch (error) {}
}

//async
const asyncPostParliamentaryData = async (data, authtoken) => {
  try {
    const response = await postParliamentaryData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddParliamentary({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostParliamentaryData, data, authtoken);

    if (response.status == "success") {
      toast("🦄 Parliamentary seat added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(getParliamentaryValue(authtoken, 1, 10));
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addParliamentaryFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function* onUpdateParliamentary({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateParliamentaryData, id, data, token);

    toast("🦄 Parliamentary seat updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getParliamentaryValue(token, 1, 10));
    if (response.status === 200) {
      history.push("/parliamentary-seat");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateParliamentaryFail(error));
  }
}

function* GeoInformationSaga() {
  yield takeEvery(GET_DIVISION_VALUE, workerGetDivision);
  yield takeEvery(ADD_NEW_DIVISION, onAddDivision);
  yield takeEvery(GET_DISTRICT_VALUE, workerGetDistrict);
  yield takeEvery(ADD_NEW_DISTRICT, onAddDistrict);
  yield takeEvery(GET_PARLIAMENTARY_VALUE, workerGetParliamentary);
  yield takeEvery(ADD_NEW_PARLIAMENTARY, onAddParliamentary);
  yield takeEvery(GET_UPAZILA_VALUE, workerGetUpazila);
  yield takeEvery(GET_DISTRICT_BY_DIVISION, workerGetDistrictByDivision);
  yield takeEvery(ADD_NEW_UPAZILA, onAddUpazila);
  yield takeEvery(UPDATE_DIVISION, onUpdateDivision);
  yield takeEvery(UPDATE_DISTRICT, onUpdateDistrict);
  yield takeEvery(UPDATE_UPAZILA, onUpdateUpazila);
  yield takeEvery(UPDATE_PARLIAMENTARY, onUpdateParliamentary);
}

export default GeoInformationSaga;
