import {
    GET_ISSUE_STATUS,
    GET_ISSUE_STATUS_SUCCESS,
    GET_ISSUE_STATUS_FAIL,
    GET_PENDING_ISSUE_BY_SO,
    GET_PENDING_ISSUE_BY_SO_SUCCESS,
    GET_PENDING_ISSUE_BY_SO_FAIL,
    GET_PENDING_ISSUE_BY_INSTITUTE,
    GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS,
    GET_PENDING_ISSUE_BY_INSTITUTE_FAIL,
    GET_DASHBOARD_ATTENDANCE,
    GET_DASHBOARD_ATTENDANCE_SUCCESS,
    GET_DASHBOARD_ATTENDANCE_FAIL

  } from "./actionTypes";
  
  export const getIssueStatus = (authtoken, startDate, endDate) => ({
    type: GET_ISSUE_STATUS,
    payload: { authtoken, startDate, endDate},
  });
  
  export const getIssueStatusSuccess = (data) => ({
    type: GET_ISSUE_STATUS_SUCCESS,
    payload: { data },
  });
  export const getIssueStatusFail = (error) => ({
    type: GET_ISSUE_STATUS_FAIL,
    payload: error,
  });
  export const getPendingIssueBySO = (authtoken, startDate, endDate) => ({
    type: GET_PENDING_ISSUE_BY_SO,
    payload: { authtoken, startDate, endDate },
  });
  
  export const getPendingIssueBySOSuccess = (data) => ({
    type: GET_PENDING_ISSUE_BY_SO_SUCCESS,
    payload: { data },
  });
  export const getPendingIssueBySOFail = (error) => ({
    type: GET_PENDING_ISSUE_BY_SO_FAIL,
    payload: error,
  });
  export const getPendingIssueByInstitute = (authtoken, startDate, endDate) => ({
    type: GET_PENDING_ISSUE_BY_INSTITUTE,
    payload: { authtoken, startDate, endDate },
  });
  
  export const getPendingIssueByInstituteSuccess = (data) => ({
    type: GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS,
    payload: { data },
  });
  export const getPendingIssueByInstituteFail = (error) => ({
    type: GET_PENDING_ISSUE_BY_INSTITUTE_FAIL,
    payload: error,
  });
  export const getDashboardAttendance = (authtoken) => ({
    type: GET_DASHBOARD_ATTENDANCE,
    payload: { authtoken},
  });
  
  export const getDashboardAttendanceSuccess = (data) => ({
    type: GET_DASHBOARD_ATTENDANCE_SUCCESS,
    payload: { data },
  });
  export const getDashboardAttendanceFail = (error) => ({
    type: GET_DASHBOARD_ATTENDANCE_FAIL,
    payload: error,
  });
 
 
 