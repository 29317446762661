import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import PjpView from "./PjpView";

const AssignInstitute = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="User List"
      />

      <PjpView />
    </div>
  );
};

export default AssignInstitute;
