import {
  ADD_PJP_FAIL,
  ADD_PJP_SUCCESS,
  GET_PJP_VALUE,
  GET_PJP_VALUE_SUCCESS,
  UPDATE_PJP_FAIL,
  UPDATE_PJP_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  isLoading: false,
  pjpData: [],
  editPjpInfo: [],
};

const PjpReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PJP_VALUE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_PJP_VALUE_SUCCESS:
      return {
        ...state,
        pjpData: action.payload.data,
        isLoading: false,
      };

    case ADD_PJP_SUCCESS:
      return {
        ...state,
        pjp: [...state.pjp, action.payload],
        isLoading: false,
      };

    case ADD_PJP_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    // case STORE_PJP_DATA:
    //   return {
    //     ...state,
    //     editPjpInfo: action.payload,
    //   }
    case UPDATE_PJP_SUCCESS:
      return {
        ...state,
        pjp: [...state.pjp, action.payload],
        isLoading: false,
      };

    case UPDATE_PJP_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default PjpReducer;
