export const GET_ALL_INSTITUTE = "GET_ALL_INSTITUTE";
export const GET_ALL_INSTITUTE_SUCCESS = "GET_ALL_INSTITUTE_SUCCESS";
export const GET_ALL_INSTITUTE_FAIL = "GET_ALL_INSTITUTE_FAIL";

export const GET_SINGLE_INSTITUTE = "GET_SINGLE_INSTITUTE";
export const GET_SINGLE_INSTITUTE_SUCCESS = "GET_SINGLE_INSTITUTE_SUCCESS";
export const GET_SINGLE_INSTITUTE_FAIL = "GET_SINGLE_INSTITUTE_FAIL";

export const ADD_INSTITUTE = "ADD_INSTITUTE";
export const ADD_INSTITUTE_SUCCESS = "ADD_INSTITUTE_SUCCESS";
export const ADD_INSTITUTE_FAIL = "ADD_INSTITUTE_FAIL";

export const UPDATE_INSTITUTE = "UPDATE_INSTITUTE";
export const UPDATE_INSTITUTE_SUCCESS = "UPDATE_INSTITUTE_SUCCESS";
export const UPDATE_INSTITUTE_FAIL = "UPDATE_INSTITUTE_FAIL";
export const STORE_INSTITUTE_DATA = "STORE_INSTITUTE_DATA";

export const STORE_SINGLE_INSTITUTE_DATA = "STORE_INSTITUTE_DATA";

export const USER_ASSIGN_INSTITUTE = "USER_ASSIGN_INSTITUTE";
export const USER_ASSIGN_INSTITUTE_SUCCESS = "USER_ASSIGN_INSTITUTE_SUCCESS";
export const USER_ASSIGN_INSTITUTE_FAIL = "USER_ASSIGN_INSTITUTE_FAIL";
// export const ADD_INSTITUTE_SUCCESS = "ADD_INSTITUTE_SUCCESS";

//search pjp
export const SEARCH_PJP = "SEARCH_PJP";
export const SEARCH_PJP_SUCCESS = "SEARCH_PJP_SUCCESS";
export const SEARCH_PJP_FAIL = "SEARCH_PJP_FAIL";
