export const GET_ISSUE_STATUS = "GET_ISSUE_STATUS";
export const GET_ISSUE_STATUS_SUCCESS = "GET_ISSUE_STATUS_SUCCESS";
export const GET_ISSUE_STATUS_FAIL = "GET_ISSUE_STATUS_FAIL";

export const GET_PENDING_ISSUE_BY_SO = "GET_PENDING_ISSUE_BY_SO";
export const GET_PENDING_ISSUE_BY_SO_SUCCESS = "GET_PENDING_ISSUE_BY_SO_SUCCESS";
export const GET_PENDING_ISSUE_BY_SO_FAIL = "GET_PENDING_ISSUE_BY_SO_FAIL";


export const GET_PENDING_ISSUE_BY_INSTITUTE = "GET_PENDING_ISSUE_BY_INSTITUTE";
export const GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS = "GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS";
export const GET_PENDING_ISSUE_BY_INSTITUTE_FAIL = "GET_PENDING_ISSUE_BY_INSTITUTE_FAIL";



export const GET_DASHBOARD_ATTENDANCE = "GET_DASHBOARD_ATTENDANCE";
export const GET_DASHBOARD_ATTENDANCE_SUCCESS = "GET_DASHBOARD_ATTENDANCE_SUCCESS";
export const GET_DASHBOARD_ATTENDANCE_FAIL = "GET_DASHBOARD_ATTENDANCE_FAIL";



