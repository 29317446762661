//ADD PJP
export const ADD_NEW_PJP = "ADD_NEW_PJP";
export const ADD_PJP_SUCCESS = "ADD_PJP_SUCCESS";
export const ADD_PJP_FAIL = "ADD_PJP_FAIL";

//Edit PJP
export const GET_PJP_VALUE = "GET_PJP_VALUE";
export const GET_PJP_VALUE_SUCCESS = "GET_PJP_VALUE_SUCCESS";
export const GET_PJP_VALUE_FAIL = "GET_PJP_VALUE_FAIL";

//Update PJP
export const UPDATE_PJP = "UPDATE_PJP";
export const UPDATE_PJP_SUCCESS = "UPDATE_PJP_SUCCESS";
export const UPDATE_PJP_FAIL = "UPDATE_PJP_FAIL";

//export const STORE_PJP_DATA = "STORE_PJP_DATA"
