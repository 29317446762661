import {
  GET_ALL_USER_ATTENDANCE,
  GET_ALL_USER_ATTENDANCE_FAIL,
  GET_ALL_USER_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE,
  GET_ATTENDANCE_FAIL,
  GET_ATTENDANCE_SUCCESS,
  GET_SINGLE_USER_ATTENDANCE,
  GET_SINGLE_USER_ATTENDANCE_FAIL,
  GET_SINGLE_USER_ATTENDANCE_SUCCESS,
} from "./actionTypes";

export const getAttendance = (
  authtoken,
  lineManagerId,
  userId,
  fromDate,
  handleList,
  currentPage,
  status
) => ({
  type: GET_ATTENDANCE,
  payload: {
    authtoken,
    lineManagerId,
    userId,
    fromDate,
    handleList,
    currentPage,
    status,
  },
});

export const getAttendanceSuccess = (data) => ({
  type: GET_ATTENDANCE_SUCCESS,
  payload: { data },
});

export const getAttendanceFail = (error) => ({
  type: GET_ATTENDANCE_FAIL,
  payload: error,
});

export const getSingleUserAttendance = (
  authtoken,
  fromDate,
  toDate,
  userId
) => ({
  type: GET_SINGLE_USER_ATTENDANCE,
  payload: {
    authtoken,
    fromDate,
    toDate,
    userId,
  },
});

export const getSingleUserAttendanceSuccess = (data) => ({
  type: GET_SINGLE_USER_ATTENDANCE_SUCCESS,
  payload: { data },
});

export const getSingleUserAttendanceFail = (error) => ({
  type: GET_SINGLE_USER_ATTENDANCE_FAIL,
  payload: error,
});

export const getAllUserAttendance = (authtoken, fromDate, toDate) => ({
  type: GET_ALL_USER_ATTENDANCE,
  payload: {
    authtoken,
    fromDate,
    toDate,
  },
});

export const getAllUserAttendanceSuccess = (data) => ({
  type: GET_ALL_USER_ATTENDANCE_SUCCESS,
  payload: { data },
});

export const getAllUserAttendanceFail = (error) => ({
  type: GET_ALL_USER_ATTENDANCE_FAIL,
  payload: error,
});
