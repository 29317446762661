import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import {
  getDashboardAttendance,
  getIssueStatus,
  getPendingIssueByInstitute,
  getPendingIssueBySO,
} from "../../store/Dashboard/actions";
import "./dashboard.scss";
import pendingIcon from "./img/flash-circle.svg";
import solveIcon from "./img/tick-circle.svg";
import userIcon from "./img/user-tick.svg";
const Dashboard = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    authtoken,
    issueStatus,
    issueStatusLoading,
    dashboardAttendance,
    dashboardAttendanceLoading,
    pendingIssueBySO,
    pendingIssueBySOLoading,
    pendingIssueByInstitute,
    pendingIssueByInstituteLoading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    issueStatus: state?.DashboardReducer?.issueStatus,
    issueStatusLoading: state?.DashboardReducer?.issueStatusLoading,
    pendingIssueBySO: state?.DashboardReducer?.pendingIssueBySO,
    pendingIssueBySOLoading: state?.DashboardReducer?.pendingIssueBySOLoading,
    pendingIssueByInstitute: state?.DashboardReducer?.pendingIssueByInstitute,
    pendingIssueByInstituteLoading:
      state?.DashboardReducer?.pendingIssueByInstituteLoading,
    dashboardAttendance: state?.DashboardReducer?.dashboardAttendance,
    dashboardAttendanceLoading:
      state?.DashboardReducer?.dashboardAttendanceLoading,
  }));
  // console.log("dashboardAttendance", dashboardAttendance);
  // console.log("issueStatus", issueStatus);
  // console.log("pendingIssueBySO", pendingIssueBySO);
  // console.log('pendingIssueByInstitute',pendingIssueByInstitute);
  useEffect(() => {
    dispatch(getIssueStatus(authtoken, startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(getPendingIssueBySO(authtoken, startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(getPendingIssueByInstitute(authtoken, startDate, endDate));
  }, [startDate, endDate]);
  useEffect(() => {
    dispatch(getDashboardAttendance(authtoken));
  }, []);
  const handleStartDate = (e) => {
    const value = e.target.value;

    setStartDate(value);
  };
  const handleEndDate = (e) => {
    const value = e.target.value;

    setEndDate(value);
  };

  return (
    <Container fluid>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Home"
        pageTitle="Dashboard"
      />
      <div className="dashboard-body-top-content-header">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <Form className="form-horizontal-form-wrap">
                  <Form.Group className="form-data-filtering">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </Form.Group>
                  <Form.Group className="form-data-filtering">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={handleEndDate}
                    />
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-data-chart-area-wrap">
        <Row>
          <Col lg={4}>
            <div className="dashboard-right-content">
              {issueStatusLoading ? (
                <div className="text-center mt-5 pt-5">
                  <div className="text-center pt-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                </div>
              ) : (
                <Row>
                  <div className="dashboard-body-top-content-header">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="mb-3">Issue Status</h6>
                        <Col lg={12}>
                          <div className="dashboard-right-single-content hjknm">
                            <span>
                              <img src={pendingIcon} alt="" />
                              <p>Pending</p>
                              {issueStatus?.data?.data?.totalLength[0]
                                ?.pendingCount ? (
                                <h6>
                                  {
                                    issueStatus?.data?.data?.totalLength[0]
                                      ?.pendingCount?.count
                                  }
                                </h6>
                              ) : (
                                <h6>0</h6>
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="dashboard-right-single-content hvjdbn">
                            <span>
                              <img src={solveIcon} alt="" />
                              <p>Solved</p>
                              {issueStatus?.data?.data?.totalLength[0]
                                ?.solvedCount ? (
                                <h6>
                                  {
                                    issueStatus?.data?.data?.totalLength[0]
                                      ?.solvedCount?.count
                                  }
                                </h6>
                              ) : (
                                <h6>0</h6>
                              )}
                            </span>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </Row>
              )}
            </div>
          </Col>
          {pendingIssueBySOLoading ? (
            <div className="text-center mt-5 pt-5">
              <div className="text-center pt-5">
                <Spinner animation="border" variant="warning" />
              </div>
            </div>
          ) : (
            <Col lg={8}>
              <div className="single-chart-data">
                <h6 className="mb-3">Top Pending Issues by Support Officer</h6>
                <Table striped bordered hover>
                  <thead style={{ background: "#FFEEE1" }}>
                    <tr>
                      <th>SL</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>
                        Pending{" "}
                        {/* <button
                          className="btn text-end"
                          type="button"
                          onClick={() => push("/issue-list")}
                        >
                          <i class="fa-solid fa-arrow-right-from-bracket"></i>
                        </button> */}
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">Issue list</Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <button
                              className="btn text-end"
                              type="button"
                              onClick={() => push("/issue-list")}
                            >
                              <i class="fa-solid fa-arrow-right-from-bracket"></i>
                            </button>
                          </span>
                        </OverlayTrigger>
                      </th>
                    </tr>
                  </thead>
                  {(pendingIssueBySO?.data?.data?.totalIssue || []).map(
                    (data, key) => {
                      return (
                        <>
                          <tbody>
                            <tr>
                              <td>{key + 1}</td>
                              <td>{data?.userInfo?.name}</td>
                              <td>{data?.userInfo?.email}</td>
                              <td>{data?.pendingNumber}</td>
                            </tr>
                          </tbody>
                        </>
                      );
                    }
                  )}
                </Table>
              </div>
            </Col>
          )}
        </Row>{" "}
        <Row>
          {pendingIssueByInstituteLoading ? (
            <div className="text-center mt-5 pt-5">
              <div className="text-center pt-5">
                <Spinner animation="border" variant="warning" />
              </div>
            </div>
          ) : (
            <Col lg={8}>
              <div className="single-chart-data">
                <h6 className="mb-3">Top Pending Issues by Institute</h6>
                <Table striped bordered hover>
                  <thead style={{ background: "#FFEEE1" }}>
                    <tr>
                      <th>SL</th>
                      <th>EIIN No.</th>
                      <th>Institute name</th>
                      <th>Upazila</th>
                      {/* <th>SO Name</th> */}
                      <th>Pending</th>
                      <th>Solved</th>
                    </tr>
                  </thead>
                  {(
                    pendingIssueByInstitute?.data?.data?.totalInstitute || []
                  ).map((data, key) => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td>{key + 1}</td>
                            <td>{data?.instituteInfo?.eiinNumber}</td>
                            <td>{data?.instituteInfo?.nameEnglish}</td>
                            <td>{data?.upozilaInfo?.name}</td>
                            {/* <td>@mdo</td> */}
                            <td>{data?.pendingNumber}</td>
                            <td>{data?.solvedNumber}</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
                </Table>
              </div>
            </Col>
          )}
          <Col lg={4}>
            <div className="dashboard-right-content">
              {dashboardAttendanceLoading ? (
                <div className="text-center mt-5 pt-5">
                  <div className="text-center pt-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                </div>
              ) : (
                <Row>
                  <Col lg={12}>
                    <div
                      className="dashboard-body-top-content-header"
                      // style={{ boxShadow: "none !important" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <h6 className="mb-3">Attendance</h6>
                          <div className="dashboard-right-single-content hjkdgfnm">
                            <span>
                              <img src={userIcon} alt="" />
                              <p>Total attend</p>
                              {dashboardAttendance?.data?.data
                                ?.totalattendance[0] ? (
                                <h6>
                                  {
                                    dashboardAttendance?.data?.data
                                      ?.totalattendance[0].total
                                  }
                                </h6>
                              ) : (
                                <h6>0</h6>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Dashboard;
