import {
  GET_ISSUE_STATUS_SUCCESS,
  GET_ISSUE_STATUS_FAIL,
  GET_PENDING_ISSUE_BY_SO_SUCCESS,
  GET_PENDING_ISSUE_BY_SO_FAIL,
  GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS,
  GET_PENDING_ISSUE_BY_INSTITUTE_FAIL,
  GET_DASHBOARD_ATTENDANCE_SUCCESS,
  GET_DASHBOARD_ATTENDANCE_FAIL,
} from "./actionTypes";
const INIT_STATE = {
  issueStatus: [],
  pendingIssueBySO: [],
  pendingIssueByInstitute: [],
  dashboardAttendance: [],
  issueStatusLoading: true,
  pendingIssueBySOLoading: true,
  pendingIssueByInstituteLoading: true,
  dashboardAttendanceLoading: true,
};
const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ISSUE_STATUS_SUCCESS:
      return {
        ...state,
        issueStatus: action.payload,
        issueStatusLoading: false,
      };
    case GET_ISSUE_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        issueStatusLoading: false,
      };
    case GET_PENDING_ISSUE_BY_SO_SUCCESS:
      return {
        ...state,
        pendingIssueBySO: action.payload,
        pendingIssueBySOLoading: false,
      };

    case GET_PENDING_ISSUE_BY_SO_FAIL:
      return {
        ...state,
        error: action.payload,
        pendingIssueBySOLoading: false,
      };

    case GET_PENDING_ISSUE_BY_INSTITUTE_SUCCESS:
      return {
        ...state,
        pendingIssueByInstitute: action.payload,
        pendingIssueByInstituteLoading: false,
      };
    case GET_PENDING_ISSUE_BY_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        pendingIssueByInstituteLoading: false,
      };
    case GET_DASHBOARD_ATTENDANCE_SUCCESS:
      return {
        ...state,
        dashboardAttendance: action.payload,
        dashboardAttendanceLoading: false,
      };
    case GET_DASHBOARD_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        dashboardAttendanceLoading: false,
      };

    default:
      return state;
  }
};
export default DashboardReducer;
