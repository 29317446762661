import {
  ADD_NEW_PJP,
  ADD_PJP_FAIL,
  ADD_PJP_SUCCESS,
  GET_PJP_VALUE,
  GET_PJP_VALUE_SUCCESS,
  UPDATE_PJP,
  UPDATE_PJP_FAIL,
  UPDATE_PJP_SUCCESS,
} from "./actionTypes";

//ROUTE PLAN
export const getPjpValue = (authtoken, userId, fromDate, toDate) => ({
  type: GET_PJP_VALUE,
  payload: { authtoken, userId, fromDate, toDate },
});

export const getPjpValueSuccess = (data) => ({
  type: GET_PJP_VALUE_SUCCESS,
  payload: { data },
});

export const addNewPjp = (data, history, authtoken) => ({
  type: ADD_NEW_PJP,
  payload: { data, history, authtoken },
});
export const addPjpSuccess = (data) => ({
  type: ADD_PJP_SUCCESS,
  payload: data,
});

export const addPjpFail = (error) => ({
  type: ADD_PJP_FAIL,
  payload: error,
});
// export const storePjpData = (name, data) => ({
//   type: STORE_PJP_DATA,
//   payload: { name, data },
// })

export const updatePjp = (data, history, authtoken) => ({
  type: UPDATE_PJP,
  payload: { data, history, authtoken },
});

export const updatePjpSuccess = (data) => ({
  type: UPDATE_PJP_SUCCESS,
  payload: { data },
});

export const updatePjpFail = (error) => ({
  type: UPDATE_PJP_FAIL,
  payload: error,
});
