import React from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import AllInstitute from "./AllInstitute";
import InstituteFiltering from "./InstituteFiltering";
import "./users.scss";
const InstituteList = () => {
  return (
    <div>
      <Container fluid>
        <Breadcrumb
          leftTitle="Institute"
          rightTitle="Dashboard"
          pageTitle="Institute"
        />
        <InstituteFiltering />
       
      </Container>
    </div>
  );
};

export default InstituteList;
