import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import IssueFiltering from "./IssueFiltering";

const IssueList = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="issue List"
      />
      <IssueFiltering />
    </div>
  );
};

export default IssueList;
