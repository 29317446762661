export const GET_INSTITUTE_BY_SO = "GET_INSTITUTE_BY_SO";
export const GET_INSTITUTE_BY_SO_SUCCESS = "GET_INSTITUTE_BY_SO_SUCCESS";
export const GET_INSTITUTE_BY_SO_FAIL = "GET_INSTITUTE_BY_SO_FAIL";

export const GET_ISSUE_LIST = "GET_ISSUE_LIST";
export const GET_ISSUE_LIST_SUCCESS = "GET_ISSUE_LIST_SUCCESS";
export const GET_ISSUE_LIST_FAIL = "GET_ISSUE_LIST_FAIL";

export const GET_ISSUE_DETAILS = "GET_ISSUE_DETAILS";
export const GET_ISSUE_DETAILS_SUCCESS = "GET_ISSUE_DETAILS_SUCCESS";
export const GET_ISSUE_DETAILS_FAIL = "GET_ISSUE_DETAILS_FAIL";
// add issue
export const ADD_ISSUE = "ADD_ISSUE";
export const ADD_ISSUE_SUCCESS = "ADD_ISSUE_SUCCESS";
export const ADD_ISSUE_FAIL = "ADD_ISSUE_FAIL";
// update issue
export const UPDATE_ISSUE = "UPDATE_ISSUE";
export const UPDATE_ISSUE_SUCCESS = "UPDATE_ISSUE_SUCCESS";
export const UPDATE_ISSUE_FAIL = "UPDATE_ISSUE_FAIL";
export const STORE_ISSUE_DATA = "STORE_ISSUE_DATA";

export const POST_REMARKS = "POST_REMARKS";
export const POST_REMARKS_SUCCESS = "POST_REMARKS_SUCCESS";
export const POST_REMARKS_FAIL = "POST_REMARKS_FAIL";
export const STORE_ISSUE_LIMITATION = "STORE_ISSUE_LIMITATION";


