export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_USER_SUCCESS = "GET_ALL_USER_SUCCESS";
export const GET_ALL_USER_FAIL = "GET_ALL_USER_FAIL";

export const GET_SUPERVISOR = "GET_SUPERVISOR";
export const GET_SUPERVISOR_SUCCESS = "GET_SUPERVISOR_SUCCESS";
export const GET_SUPERVISOR_FAIL = "GET_SUPERVISOR_FAIL";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

//EDIT USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const STORE_USER_DATA = "STORE_USER_DATA";

//search institute
export const SEARCH_INSTITUTE = "SEARCH_INSTITUTE";
export const SEARCH_INSTITUTE_SUCCESS = "SEARCH_INSTITUTE_SUCCESS";
export const SEARCH_INSTITUTE_FAIL = "SEARCH_INSTITUTE_FAIL";

//reset password
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
