import {
  ADD_DISTRICT_FAIL,
  ADD_DISTRICT_SUCCESS,
  ADD_DIVISION_FAIL,
  ADD_DIVISION_SUCCESS,
  ADD_NEW_DISTRICT,
  ADD_NEW_DIVISION,
  ADD_NEW_PARLIAMENTARY,
  ADD_NEW_UPAZILA,
  ADD_PARLIAMENTARY_FAIL,
  ADD_PARLIAMENTARY_SUCCESS,
  ADD_UPAZILA_FAIL,
  ADD_UPAZILA_SUCCESS,
  GET_DISTRICT_BY_DIVISION,
  GET_DISTRICT_BY_DIVISION_SUCCESS,
  GET_DISTRICT_VALUE,
  GET_DISTRICT_VALUE_SUCCESS,
  GET_DIVISION_VALUE,
  GET_DIVISION_VALUE_SUCCESS,
  GET_PARLIAMENTARY_VALUE,
  GET_PARLIAMENTARY_VALUE_SUCCESS,
  GET_UPAZILA_VALUE,
  GET_UPAZILA_VALUE_SUCCESS,
  STORE_DISTRICT_DATA,
  STORE_DIVISION_DATA,
  STORE_PARLIAMENTARY_DATA,
  STORE_UPAZILA_DATA,
  UPDATE_DISTRICT,
  UPDATE_DISTRICT_FAIL,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DIVISION,
  UPDATE_DIVISION_FAIL,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_PARLIAMENTARY,
  UPDATE_PARLIAMENTARY_FAIL,
  UPDATE_PARLIAMENTARY_SUCCESS,
  UPDATE_UPAZILA,
  UPDATE_UPAZILA_FAIL,
  UPDATE_UPAZILA_SUCCESS,
} from "./actionTypes";

//DIVISION
export const getDivisionValue = (authtoken, currentPage, pageRange) => ({
  type: GET_DIVISION_VALUE,
  payload: { authtoken, currentPage, pageRange },
});

export const getDivisionValueSuccess = (data) => ({
  type: GET_DIVISION_VALUE_SUCCESS,
  payload: { data },
});

export const addNewDivision = (data, history, authtoken) => ({
  type: ADD_NEW_DIVISION,
  payload: { data, history, authtoken },
});
export const addDivisionSuccess = (data) => ({
  type: ADD_DIVISION_SUCCESS,
  payload: data,
});

export const addDivisionFail = (error) => ({
  type: ADD_DIVISION_FAIL,
  payload: error,
});
export const storeDivisionData = (name, data, id) => ({
  type: STORE_DIVISION_DATA,
  payload: { name, data, id },
});

export const updateDivision = (id, data, token, history) => ({
  type: UPDATE_DIVISION,
  payload: { data, history, token, id },
});

export const updateDivisionSuccess = (id, data) => ({
  type: UPDATE_DIVISION_SUCCESS,
  payload: { id, data },
});

export const updateDivisionFail = (error) => ({
  type: UPDATE_DIVISION_FAIL,
  payload: error,
});

//DISTRICT
export const getDistrictValue = (
  authtoken,
  dataRange,
  currentPage,
  distByDiv
) => ({
  type: GET_DISTRICT_VALUE,
  payload: { authtoken, dataRange, currentPage, distByDiv },
});

export const getDistrictValueSuccess = (data) => ({
  type: GET_DISTRICT_VALUE_SUCCESS,
  payload: { data },
});

export const addNewDistrict = (data, history, authtoken) => ({
  type: ADD_NEW_DISTRICT,
  payload: { data, history, authtoken },
});
export const addDistrictSuccess = (data) => ({
  type: ADD_DISTRICT_SUCCESS,
  payload: data,
});

export const addDistrictFail = (error) => ({
  type: ADD_DISTRICT_FAIL,
  payload: error,
});
export const storeDistrictData = (name, data, id) => ({
  type: STORE_DISTRICT_DATA,
  payload: { name, data, id },
});

export const updateDistrict = (id, data, token, history) => ({
  type: UPDATE_DISTRICT,
  payload: { data, history, token, id },
});

export const updateDistrictSuccess = (id, data) => ({
  type: UPDATE_DISTRICT_SUCCESS,
  payload: { id, data },
});

export const updateDistrictFail = (error) => ({
  type: UPDATE_DISTRICT_FAIL,
  payload: error,
});
//UPAZILA
export const getUpazilaValue = (
  authtoken,
  dataRange,
  currentPage,
  upazilaByDist
) => ({
  type: GET_UPAZILA_VALUE,
  payload: { authtoken, dataRange, currentPage, upazilaByDist },
});

export const getUpazilaValueSuccess = (data) => ({
  type: GET_UPAZILA_VALUE_SUCCESS,
  payload: { data },
});
//filter
export const getDistrictByDivision = (
  authtoken,
  id,
  dataRange,
  currentPage
) => ({
  type: GET_DISTRICT_BY_DIVISION,
  payload: { authtoken, id, dataRange, currentPage },
});

export const getDistrictByDivisionSuccess = (data) => ({
  type: GET_DISTRICT_BY_DIVISION_SUCCESS,
  payload: { data },
});

export const addNewUpazila = (data, history, authtoken) => ({
  type: ADD_NEW_UPAZILA,
  payload: { data, history, authtoken },
});
export const addUpazilaSuccess = (data) => ({
  type: ADD_UPAZILA_SUCCESS,
  payload: data,
});

export const addUpazilaFail = (error) => ({
  type: ADD_UPAZILA_FAIL,
  payload: error,
});

export const storeUpazilaData = (name, data, id) => ({
  type: STORE_UPAZILA_DATA,
  payload: { name, data, id },
});

export const updateUpazila = (id, data, token, history) => ({
  type: UPDATE_UPAZILA,
  payload: { id, data, token, history },
});

export const updateUpazilaSuccess = (id, data) => ({
  type: UPDATE_UPAZILA_SUCCESS,
  payload: { id, data },
});

export const updateUpazilaFail = (error) => ({
  type: UPDATE_UPAZILA_FAIL,
  payload: error,
});

//PARLIAMENTARY
export const getParliamentaryValue = (
  authtoken,
  dataRange,
  currentPage,
  parliamentaryByDist
) => ({
  type: GET_PARLIAMENTARY_VALUE,
  payload: { authtoken, dataRange, currentPage, parliamentaryByDist },
});

export const getParliamentaryValueSuccess = (data) => ({
  type: GET_PARLIAMENTARY_VALUE_SUCCESS,
  payload: { data },
});

export const addNewParliamentary = (data, history, authtoken) => ({
  type: ADD_NEW_PARLIAMENTARY,
  payload: { data, history, authtoken },
});
export const addParliamentarySuccess = (data) => ({
  type: ADD_PARLIAMENTARY_SUCCESS,
  payload: data,
});

export const addParliamentaryFail = (error) => ({
  type: ADD_PARLIAMENTARY_FAIL,
  payload: error,
});
export const storeParliamentaryData = (name, data, id) => ({
  type: STORE_PARLIAMENTARY_DATA,
  payload: { name, data },
});

export const updateParliamentary = (id, data, token, history) => ({
  type: UPDATE_PARLIAMENTARY,
  payload: { id, data, token, history },
});

export const updateParliamentarySuccess = (id, data) => ({
  type: UPDATE_PARLIAMENTARY_SUCCESS,
  payload: { id, data },
});

export const updateParliamentaryFail = (error) => ({
  type: UPDATE_PARLIAMENTARY_FAIL,
  payload: error,
});
