import {
  ADD_ISSUE,
  ADD_ISSUE_FAIL,
  ADD_ISSUE_SUCCESS,
  GET_INSTITUTE_BY_SO,
  GET_INSTITUTE_BY_SO_FAIL,
  GET_INSTITUTE_BY_SO_SUCCESS,
  GET_ISSUE_DETAILS,
  GET_ISSUE_DETAILS_FAIL,
  GET_ISSUE_DETAILS_SUCCESS,
  GET_ISSUE_LIST,
  GET_ISSUE_LIST_FAIL,
  GET_ISSUE_LIST_SUCCESS,
  POST_REMARKS,
  POST_REMARKS_FAIL,
  POST_REMARKS_SUCCESS,
  STORE_ISSUE_DATA,
  UPDATE_ISSUE,
  UPDATE_ISSUE_FAIL,
  UPDATE_ISSUE_SUCCESS,
  STORE_ISSUE_LIMITATION
} from "./actionTypes";

export const getInstituteBySO = (authtoken, id) => ({
  type: GET_INSTITUTE_BY_SO,
  payload: { authtoken, id },
});

export const getInstituteBySOSuccess = (data) => ({
  type: GET_INSTITUTE_BY_SO_SUCCESS,
  payload: { data },
});
export const getInstituteBySOFail = (error) => ({
  type: GET_INSTITUTE_BY_SO_FAIL,
  payload: error,
});
export const getIssue = (data, history, authtoken) => ({
  type: GET_ISSUE_LIST,
  payload: { data, history, authtoken },
});

export const getIssueSuccess = (data) => ({
  type: GET_ISSUE_LIST_SUCCESS,
  payload: data,
});

export const getIssueFail = (error) => ({
  type: GET_ISSUE_LIST_FAIL,
  payload: error,
});

//add issue
export const addIssue = (data, history, authtoken) => ({
  type: ADD_ISSUE,
  payload: { data, history, authtoken },
});

export const addIssueSuccess = (data) => ({
  type: ADD_ISSUE_SUCCESS,
  payload: data,
});

export const addIssueFail = (error) => ({
  type: ADD_ISSUE_FAIL,
  payload: error,
});

//UPDATE issue
export const storeIssueData = (name, data) => ({
  type: STORE_ISSUE_DATA,
  payload: { name, data },
});

export const updateIssue = (id, data, token, history) => ({
  type: UPDATE_ISSUE,
  payload: { id, data, token, history },
});

export const updateIssueSuccess = (id, data) => ({
  type: UPDATE_ISSUE_SUCCESS,
  payload: { id, data },
});

export const updateIssueFail = (error) => ({
  type: UPDATE_ISSUE_FAIL,
  payload: error,
});
export const getIssueDetails = (authtoken, id) => ({
  type: GET_ISSUE_DETAILS,
  payload: { authtoken, id },
});

export const getIssueDetailsSuccess = (data) => ({
  type: GET_ISSUE_DETAILS_SUCCESS,
  payload: { data },
});
export const getIssueDetailsFail = (error) => ({
  type: GET_ISSUE_DETAILS_FAIL,
  payload: error,
});
export const postRemarks = (data, history, authtoken, obj) => ({
  type: POST_REMARKS,
  payload: { data, history, authtoken, obj },
});

export const postRemarksSuccess = (data) => ({
  type: POST_REMARKS_SUCCESS,
  payload: data,
});

export const postRemarksFail = (error) => ({
  type: POST_REMARKS_FAIL,
  payload: error,
});

export const getStoreLimitation = (data)  => ({
  type: STORE_ISSUE_LIMITATION,
  payload: { data },
});


