import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import excelFormat from "../../Components/excel/geo.xlsx";
import { toaster } from "../../Custom Helper/Custom/Excel toaster";
import { post } from "../../helpers/api_helper";
import useExcelReader from "../../Hooks/useExcelReader";
import {
  addNewUpazila,
  getDistrictByDivision,
  getDistrictValue,
  getDivisionValue,
  getUpazilaValue,
  storeUpazilaData,
  updateUpazila,
} from "../../store/actions";
import AddFileUpload from "../Common/AddFileUpload";
import Pagination from "../Common/Pagination";

const UpazilaView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [createDivistion, setcreateDivistion] = useState(false);
  const [editDivistion, setEditDivistion] = useState(false);
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [upazila, setUpazila] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [upazilaByDist, setUpazilaByDist] = useState("");
  const [openExcel, setOpenExcel] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const { items, readExcel } = useExcelReader();
  // console.log(`🦄 ~ file: Dashboard.js ~ line 21 ~ Dashboard ~ items`, items);/
  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [openExel, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpenExcel(!openExcel);
  const [key, setKey] = useState("");
  const [objData, setObjData] = useState({});

  const {
    divisionData,
    loading,
    authtoken,
    editUpazilaInfo,
    districtData,
    upazilaData,
    districtByDivisionData,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    upazilaData: state.GeoInformationReducer.upazilaData,
    editUpazilaInfo: state.GeoInformationReducer.editUpazilaInfo,
    districtByDivisionData: state.GeoInformationReducer.districtByDivisionData,
  }));

  //division list
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);

  //district list
  useEffect(() => {
    dispatch(getDistrictValue(authtoken, currentPage, pageRange));
  }, [currentPage, pageRange]);

  //upazila list
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(
        getUpazilaValue(authtoken, currentPage, pageRange, upazilaByDist)
      );
    } else {
      dispatch(
        getUpazilaValue(authtoken, currentPage, pageRange, upazilaByDist)
      );
    }
  }, [currentPage, pageRange, upazilaByDist]);

  let totalPageNumber = Math.ceil(upazilaData?.totalLength / pageRange);
  //upazila list

  //add upazila
  const onSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.name = upazila;
    obj.division = division;
    obj.district = district;
    dispatch(addNewUpazila(obj, history, authtoken));
    setcreateDivistion(false);
  };
  //add upazila

  //edit upazila
  const onEditSubmit = (e, data) => {
    e.preventDefault();
    let obj = {};
    // obj.name = upazila ? upazila : data.name;
    // obj.division = division ? division : data.district.division._id;
    // obj.district = district ? district : data.district._id;

    obj.name = objData?.[key]?.upazila ? objData?.[key]?.upazila : data?.name;
    obj.division = objData?.[key]?.division
      ? objData?.[key]?.division
      : data?.district?.division._id;
    obj.district = objData?.[key]?.district
      ? objData?.[key]?.district
      : data?.district._id;
    dispatch(updateUpazila(data._id, obj, authtoken, history));
    setEditDivistion(false);
  };

  const handleEditUpazila = (data, id) => {
    setKey(id);
    dispatch(storeUpazilaData("singleUpazila", data, id));
  };
  //filter
  const handleDivision = (data) => {
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  const handleDistrict = (value) => {
    if (!!value) {
      setUpazilaByDist(value);
    } else {
      setUpazilaByDist();
    }
  };
  const handleSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      console.log(element);
      const data = {
        Division: element?.DIVISION,
        District: element?.DISTRICT,
        Upazila: element?.UPAZILA,
      };

      await post("/upazila/geo-create-excel", data, {
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          console.log("error", error);
          setResults((prev) => [...prev, { status: "failed" }]);
        });

      console.log(i);
      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster("success", "All data upload successful");
      }
    }
  };

  const tableHead = items?.[0] || {};
  console.log(
    "editUpazilaInfo?.[key]?.data?.district?._id",
    editUpazilaInfo?.[key]?.data?.district?._id
  );
  return (
    <div>
      <Modal
        open={createDivistion}
        onClose={() => setcreateDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form mb-4">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setDivision(e._id);
                  handleDivision({ name: e.name, _id: e._id });
                }}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Select District</Form.Label>
              <Select
                options={districtByDivisionData?.districts}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => setDistrict(e._id)}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type Upazila</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Upazila"
                onChange={(e) => setUpazila(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              Create Upazila
            </Button>
          </Form>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5 mb-4">
          <div className="mt-3">
            <h6>Upload Excel</h6>
          </div>
          <div className="ml-auto">
            <a href={excelFormat}>
              <button className="btn btn-secondary">
                <i class="fa fa-download me-1" aria-hidden="true"></i>Download
                Format
              </button>
            </a>
          </div>
        </div>
        <AddFileUpload
          setSelectedFiles={setSelectedFiles}
          acceptFile={
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          handleFile={(file) => {
            readExcel(file);
          }}
        />
        <Modal
          open={openExcel}
          toggle={toggle}
          size="lg"
          onClose={() => setOpenExcel(!openExcel)}
        >
          {/* <ModalBody> */}
          <Table className="table table-responsive">
            <thead>
              <tr>
                <th>No.</th>
                {Object.keys(tableHead)?.map((item, idx) => (
                  <th key={idx}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items?.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item?.DIVISION}</td>
                  <td>{item?.DISTRICT}</td>
                  <td>{item?.UPAZILA}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* </ModalBody> */}
        </Modal>
        {items?.length > 0 ? (
          <div className="mt-4 text-center">
            <button className="btn btn-primary me-2" onClick={toggle}>
              Preview
            </button>
            <button
              disabled={adding}
              className="btn btn-primary "
              onClick={handleSubmit}
            >
              {adding ? "Submitting..." : "Submit"}
            </button>
          </div>
        ) : null}
      </Modal>{" "}
      <Modal
        open={editDivistion}
        onClose={() => setEditDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                // onChange={(e) => {
                //   setDivision(e._id);
                //   handleDivision({ name: e.name, _id: e._id });
                // }}
                onChange={(e) => {
                  setDivision(e._id);
                  handleDivision({ name: e.name, _id: e._id });
                  setObjData({
                    ...objData,
                    [key]: {
                      ...objData[key],
                      division: e._id,
                    },
                  });
                }}
                defaultValue={divisionData?.divisions?.filter(
                  (dta) =>
                    dta._id ===
                    editUpazilaInfo?.[key]?.data?.district?.division?._id
                )}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Select District</Form.Label>
              <Select
                options={districtByDivisionData?.districts}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setDistrict(e._id);
                  setObjData({
                    ...objData,
                    [key]: {
                      ...objData[key],
                      district: e._id,
                    },
                  });
                }}
                defaultValue={districtData?.districts?.filter(
                  (dta) =>
                    dta._id === editUpazilaInfo?.[key]?.data?.district?._id
                )}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type Upazila</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Upazila"
                onChange={(e) => {
                  setUpazila(e.target.value);
                  setObjData({
                    ...objData,
                    [key]: {
                      ...objData[key],
                      upazila: e.target.value,
                    },
                  });
                }}
                defaultValue={editUpazilaInfo?.[key]?.data?.name}
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onEditSubmit(e, editUpazilaInfo?.[key].data)}
            >
              Update Upazila
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Upazila List</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input type="search" name="" id="" placeholder="Search..." />
                </div>
              </div>
            </div>
            <div className="filter-box">
              <Select
                options={divisionData?.divisions}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                placeholder="Select Division"
                isClearable
                onChange={handleDivision}
              />
            </div>{" "}
            <div className="filter-box">
              <Select
                options={districtByDivisionData?.districts}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                placeholder="Select District"
                onChange={handleDistrict}
                isClearable
              />
            </div>{" "}
            <div className="filter-box">
              <Form.Select
                aria-label="10"
                onChange={(e) => {
                  setPageRange(e.target.value);
                }}
                // value={pageRange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-box add-division-btn">
              <button
                className="btn btn-success"
                onClick={() => setcreateDivistion(true)}
              >
                Create Upazila
              </button>
            </div>
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Division </th>
                <th>District </th>
                <th>Upazila </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {upazilaData?.upazilas?.length > 0 ? (
                upazilaData?.upazilas.map((data, idx) => (
                  <tr key={idx}>
                    <td>
                      {idx +
                        (currentPage == 1
                          ? 0
                          : currentPage * pageRange - pageRange) +
                        1}
                    </td>
                    <td>{data.district.division.name}</td>

                    <td>{data.district.name}</td>
                    <td>{data.name}</td>
                    <td>
                      <Button
                        variant="info"
                        className="btn-sm text-white"
                        onClick={() => {
                          setEditDivistion(true);
                          handleEditUpazila(data, idx);
                        }}
                      >
                        <i class="fa-solid fa-eye-dropper"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UpazilaView;
