import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import excelFormat from "../../Components/excel/pjp.xlsx";
import { toaster } from "../../Custom Helper/Custom/Excel toaster";
import { post } from "../../helpers/api_helper";
import useExcelReader from "../../Hooks/useExcelReader";
import { addNewPjp, getPjpValue, updatePjp } from "../../store/actions";
import { getInstitution } from "../../store/Instituition/actions";
import {
  getSearchInstitute,
  getSuperVisor,
  getUserDetails,
} from "../../store/User/actions";
import AddFileUpload from "../Common/AddFileUpload";
import Pagination from "../Common/Pagination";
const AssignInstituteView = () => {
  // const history = useHistory();
  const [instituteList, setInstituteList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [upazila, setUpazila] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  const [instituitionList, setInstituitionList] = useState();
  const [userId, setUserId] = useState("");
  let [institutePlan, setInstitutePlan] = useState({});
  let [updateFromDate, setUpateFromDate] = useState({});
  let [getUserId, setGetUserId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});

  const [assignPjp, setAssignPjp] = useState(false);
  const [updateAssignPjp, setUpdateAssignPjp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const { items, readExcel } = useExcelReader();

  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpenExcel(!openExcel);
  const [searchData, setSearchData] = useState("");
  const {
    authtoken,
    getUserLoading,
    superVisor,
    superVisorLoading,
    parliamentaryData,
    instituition,
    singleInstituteData,
    instituitionLoading,
    userDetails,
    searchInstituteData,
    pjpData,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    users: state?.UserReducer?.users,
    getUserLoading: state?.UserReducer?.getUserLoading,
    superVisor: state?.UserReducer?.superVisor,
    userDetails: state?.UserReducer?.userDetails,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    instituition: state.InstituteReducer.instituition,
    singleInstituteData: state.InstituteReducer.singleInstituteData,
    instituitionLoading: state.InstituteReducer.instituitionLoading,
    pjpData: state.PjpReducer.pjpData,
    searchInstituteData: state?.UserReducer?.searchInstituteData,
  }));

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearchData(e.target.value);
      if (e.target.value) {
        dispatch(getSearchInstitute(authtoken, e.target.value));
      } else {
        setSearchData("");
        dispatch(getSuperVisor(authtoken, "SO", handleList, currentPage));
      }
    }
  };
  useEffect(() => {
    let obj = {};
    obj.division = division;
    obj.district = district;
    obj.upazila = upazila;

    // console.log('obj',obj);
    dispatch(getInstitution(obj, history, authtoken));
  }, [division, district, upazila]);

  useEffect(() => {
    dispatch(getSuperVisor(authtoken, "SO", handleList, currentPage));
  }, [handleList, currentPage]);
  let totalPageNumber = Math.ceil(
    superVisor?.data?.data?.totalLength / handleList
  );

  useEffect(() => {
    dispatch(getUserDetails(authtoken, userId));
  }, [userId]);

  //date

  const [fromDate, setFromDate] = useState(
    moment(new Date(Date.now())).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState("");
  //const [days, setDays] = useState("")
  const onFromDate = (e) => {
    setFromDate(e.target.value);
  };

  let [days, setDays] = useState(0);

  const onToDate = (e) => {
    setToDate(e.target.value);
    let now = moment(fromDate);
    let end = moment(e.target.value);
    setDays(end.diff(now, "days"));
    // call here
    dispatch(getPjpValue(authtoken, userId, fromDate, e.target.value));
  };

  const arr = [];
  const calculateDate = (dt) => {
    let newDt = moment(fromDate);
    let dta = moment(
      moment(new Date(newDt), "YYYY-MM-DD").add(dt, "days")
    ).format("YYYY-MM-DD");
    arr.push(dta);
    return dta;
  };
  const handleSetInstitute = (e, name, instituteId, serial) => {
    let InstituteDate = calculateDate(serial);
    let pjpInstituteDate = moment(InstituteDate).format("DD-MM-YYYY");
    setInstitutePlan({
      ...institutePlan,
      [serial]: {
        userId: userId,
        instituteDate: InstituteDate,
        pjpInstituteDate: pjpInstituteDate,
        id: instituteId,
        userName: name,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let arr = [];
    Object.keys(institutePlan).map((v, i) => {
      arr.push({
        userId: institutePlan[v].userId,
        userName: institutePlan[v].userName,
        institute: institutePlan[v].id,
        date: institutePlan[v].instituteDate,
        pjpDate: institutePlan[v].instituteDate,
      });
    });

    setAssignPjp(false);
    dispatch(addNewPjp(arr, history, authtoken));
  };

  const handleUpdatePjp = (index, name, _id, date, data) => {
    pjpData[index] = {
      ...pjpData[index],
      date: updateFromDate?.[index] ? updateFromDate?.[index]?.["setDt"] : date,
      institute: {
        _id: _id,
        name: name,
      },
    };
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (updateFromDate) {
      Object.keys(updateFromDate).map((v, i) => {
        pjpData[v] = {
          ...pjpData[v],
          // date: updateFromDate?.[v] ? updateFromDate?.[v]?.["setDt"] : date,
        };
      });
    }
    let arr = [];
    pjpData?.map((v, i) => {
      arr.push({
        _id: v._id,
        userId: v.user._id,
        userName: v.institute.name,
        institute: v.institute._id,
        date: v.date,
      });
    });
    updateAssignPjp(false);
    dispatch(updatePjp(arr, history, authtoken));
  };

  const excelSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      const data = {
        SO_Name: element?.SO_Name,
        Date: element?.Date,
        Institute_Name: element?.Institute_Name,
      };

      await post("/pjp/pjpexcel", data, {
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          console.log("error", error);
          setResults((prev) => [...prev, { status: "failed" }]);
        });

      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster("success", "All data upload successful");
      }
    }
    // Promise.all(promises).then(response =>{
    //   console.log('res', response);
    //   setResults((prev) => [...prev, response]);
    //   setAdding(false);
    //   toaster('success', 'All data upload successful');
    // }).catch((error)=>{
    //   setAdding(false);
    //   setResults((prev) => [...prev, { status: 'failed' }]);
    //   console.log('error', error);
    // }).finally(()=>{
    //   setIsDone(true);
    // })
  };

  const tableHead = items?.[0] || {};
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  return (
    <div>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Assign Pjp</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search..."
                    onKeyDown={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-box">
              <Form.Select aria-label="10" onChange={handleListData}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
            </div>{" "}
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Full Name </th>
                <th>User ID</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Role</th>
                <th>Regional Supervisor</th>
                <th>Action</th>
              </tr>
            </thead>
            {(searchData
              ? searchInstituteData?.data?.data?.users || []
              : superVisor?.data?.data?.users || []
            ).map((data, key) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td>
                        {key +
                          (currentPage == 1
                            ? 0
                            : currentPage * handleList - handleList) +
                          1}
                      </td>
                      <td>{data?.name}</td>
                      <td>{data?.employeeId}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phoneNumber}</td>
                      <td>{data?.role}</td>
                      <td>{data?.linemanager?.name}</td>
                      <div className="d-flex justify-content-start align-items-center">
                        <Button
                          className="btn btn-success btn-sm me-2"
                          style={{ borderRadius: "10px" }}
                          onClick={() => {
                            setUserId(data._id);
                            setAssignPjp(true);
                            setDays(0);
                          }}
                        >
                          <i
                            className="bx bx-plus
"
                          ></i>
                        </Button>
                        <Button
                          className="btn btn-primary btn-sm"
                          style={{ borderRadius: "10px" }}
                          onClick={() => {
                            setUserId(data._id);
                            setUpdateAssignPjp(true);
                            setDays(0);
                          }}
                        >
                          <i className="bx bx-edit"></i>
                        </Button>
                      </div>
                    </tr>{" "}
                  </tbody>
                </>
              );
            })}
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
          <div className="d-flex align-items-center justify-content-between mt-5 mb-4">
            <div className="mt-3">
              <h6>Upload Excel</h6>
            </div>
            <div className="ml-auto">
              <a href={excelFormat}>
                <button className="btn btn-secondary">
                  <i class="fa fa-download me-1" aria-hidden="true"></i>Download
                  Format
                </button>
              </a>
            </div>
          </div>
          <AddFileUpload
            setSelectedFiles={setSelectedFiles}
            acceptFile={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            handleFile={(file) => {
              readExcel(file);
            }}
          />
          <Modal
            open={openExcel}
            toggle={toggle}
            size="lg"
            onClose={() => setOpenExcel(!openExcel)}
          >
            {/* <ModalBody> */}
            <Table className="table table-responsive">
              <thead>
                <tr>
                  <th>No.</th>
                  {Object.keys(tableHead)?.map((item, idx) => (
                    <th key={idx}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {items?.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item?.SO_Name}</td>
                    <td>{item?.Institute_Name}</td>
                    <td>{item?.Date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* </ModalBody> */}
          </Modal>
          {items?.length > 0 ? (
            <div className="mt-4 text-center">
              <button className="btn btn-primary me-2" onClick={toggle}>
                Preview
              </button>
              <button
                disabled={adding}
                className="btn btn-primary "
                onClick={excelSubmit}
              >
                {adding ? "Submitting..." : "Submit"}
              </button>
            </div>
          ) : null}
          <Modal
            open={assignPjp}
            onClose={() => setAssignPjp(false)}
            center
            classNames={{
              overlay: "add-divisionOverlay",
              modal: "add-division-Modal",
            }}
          >
            <div className="add-division-popup-form mb-5">
              <div className="mb-4 text-center">
                <h3>Add Pjp</h3>
                <hr></hr>
              </div>

              <Form
                className="needs-validation"
                // onSubmit={RouteAdd.handleSubmit}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col lg={6}>
                    <div className="single-select-field">
                      <Form.Group className="mb-3 to-date-form">
                        <Form.Control
                          type="date"
                          name="fromDate"
                          value={fromDate}
                          onChange={(e) => onFromDate(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="single-select-field">
                      <Form.Group className="mb-3 to-date-form">
                        <Form.Control
                          type="date"
                          name="toDate"
                          onChange={(e) => onToDate(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  {days ? (
                    [...Array(parseInt(days)).keys()].map((v, i) => (
                      <>
                        <Col lg={6}>
                          <Form.Group controlId="date">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              placeholder="Date"
                              value={calculateDate(v)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <label className="mb-2">Select institute</label>
                          <Select
                            name="mainFilter"
                            classNamePrefix="select2-selection"
                            cacheOptions
                            getOptionLabel={(e) => e.nameEnglish}
                            getOptionValue={(e) => e._id}
                            //defaultValue={mainFilter?.filter(data => data.value === status)}
                            options={userDetails?.data?.data?.instituteList}
                            onChange={(e) =>
                              handleSetInstitute(e, e.nameEnglish, e._id, v)
                            }
                          />
                        </Col>
                      </>
                    ))
                  ) : (
                    <></>
                  )}

                  <div className="d-flex justify-content-center">
                    <input
                      type="submit"
                      value={"Submit"}
                      className="btn btn-success mt-4"
                    />
                  </div>
                </Row>
              </Form>
            </div>
          </Modal>
          <Modal
            open={updateAssignPjp}
            onClose={() => setUpdateAssignPjp(false)}
            center
            classNames={{
              overlay: "add-divisionOverlay",
              modal: "add-division-Modal",
            }}
          >
            <div className="add-division-popup-form">
              <div className="mb-4 text-center">
                <h3>Edit Pjp</h3>
                <hr></hr>
              </div>

              <Form
                className="needs-validation"
                // onSubmit={RouteAdd.handleSubmit}
                onSubmit={handleUpdate}
              >
                <Row>
                  <Col lg={6}>
                    <div className="single-select-field">
                      <Form.Group className="mb-3 to-date-form">
                        <Form.Control
                          type="date"
                          name="fromDate"
                          value={fromDate}
                          onChange={(e) => onFromDate(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="single-select-field">
                      <Form.Group className="mb-3 to-date-form">
                        <Form.Control
                          type="date"
                          name="toDate"
                          onChange={(e) => onToDate(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  {pjpData?.length > 0 ? (
                    pjpData?.map((data, idx) => (
                      <>
                        <Col lg={6}>
                          <Form.Group controlId="date">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              placeholder="Date"
                              defaultValue={moment(new Date(data.date)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) =>
                                setUpateFromDate({
                                  ...updateFromDate,
                                  [idx]: { setDt: e.target.value },
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <label>Select institute</label>
                          <Select
                            name="mainFilter"
                            classNamePrefix="select2-selection"
                            cacheOptions
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e._id}
                            defaultValue={superVisor?.data?.data?.users?.filter(
                              (dta) => dta.name === data?.route?.name
                            )}
                            options={superVisor?.data?.data?.users}
                            onChange={(e) =>
                              handleUpdatePjp(
                                idx,
                                e.name,
                                e._id,
                                data.date,
                                data
                              )
                            }
                            required
                          />
                        </Col>
                      </>
                    ))
                  ) : (
                    <></>
                  )}

                  <div className="d-flex justify-content-center">
                    <input
                      type="submit"
                      value={"Update"}
                      className="btn btn-success mt-4"
                    />
                  </div>
                </Row>
              </Form>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AssignInstituteView;
