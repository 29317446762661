import {
  ADD_DISTRICT_FAIL,
  ADD_DISTRICT_SUCCESS,
  ADD_DIVISION_FAIL,
  ADD_DIVISION_SUCCESS,
  ADD_PARLIAMENTARY_FAIL,
  ADD_PARLIAMENTARY_SUCCESS,
  ADD_UPAZILA_FAIL,
  ADD_UPAZILA_SUCCESS,
  GET_DISTRICT_BY_DIVISION,
  GET_DISTRICT_BY_DIVISION_SUCCESS,
  GET_DISTRICT_VALUE,
  GET_DISTRICT_VALUE_SUCCESS,
  GET_DIVISION_VALUE,
  GET_DIVISION_VALUE_SUCCESS,
  GET_PARLIAMENTARY_VALUE,
  GET_PARLIAMENTARY_VALUE_SUCCESS,
  GET_UPAZILA_VALUE,
  GET_UPAZILA_VALUE_SUCCESS,
  STORE_DISTRICT_DATA,
  STORE_DIVISION_DATA,
  STORE_PARLIAMENTARY_DATA,
  STORE_UPAZILA_DATA,
  UPDATE_DISTRICT_FAIL,
  UPDATE_DISTRICT_SUCCESS,
  UPDATE_DIVISION_FAIL,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_PARLIAMENTARY_FAIL,
  UPDATE_PARLIAMENTARY_SUCCESS,
  UPDATE_UPAZILA_FAIL,
  UPDATE_UPAZILA_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  isLoading: false,
  divisionData: [],
  districtData: [],
  upazilaData: [],
  parliamentaryData: [],
  editDivisionInfo: [],
  editDistrictInfo: [],
  editParliamentaryInfo: [],
  editUpazilaInfo: [],
  division: [],
  districtByDivisionData: [],
  parliamentary: [],
  divisionloading: false,
};

const GeoInformationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DIVISION_VALUE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_DIVISION_VALUE_SUCCESS:
      return {
        ...state,
        divisionData: action.payload.data,
        isLoading: false,
      };

    case ADD_DIVISION_SUCCESS:
      return {
        ...state,
        division: [...state.division, action.payload],
        isLoading: false,
      };

    case ADD_DIVISION_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STORE_DIVISION_DATA:
      return {
        ...state,
        editDivisionInfo: {
          ...state.editDivisionInfo,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        divisionData: state.division.map((division) =>
          division.id.toString() === action.payload.id.toString()
            ? { division, ...action.payload }
            : division
        ),
        loading: false,
      };

    case UPDATE_DIVISION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //DISTRICT

    case GET_DISTRICT_VALUE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_DISTRICT_VALUE_SUCCESS:
      return {
        ...state,
        districtData: action.payload.data,
        isLoading: false,
      };

    case ADD_DISTRICT_SUCCESS:
      return {
        ...state,
        district: [...state.district, action.payload],
        isLoading: false,
      };

    case ADD_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STORE_DISTRICT_DATA:
      return {
        ...state,
        editDistrictInfo: {
          ...state.editDistrictInfo,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_DISTRICT_SUCCESS:
      return {
        ...state,
        districtData: state.district.map((district) =>
          district.id.toString() === action.payload.id.toString()
            ? { district, ...action.payload }
            : district
        ),
        loading: false,
      };

    case UPDATE_DISTRICT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //UPAZILA
    case GET_UPAZILA_VALUE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_UPAZILA_VALUE_SUCCESS:
      return {
        ...state,
        upazilaData: action.payload.data,
        isLoading: false,
      };

    //FILTER
    case GET_DISTRICT_BY_DIVISION:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_DISTRICT_BY_DIVISION_SUCCESS:
      return {
        ...state,
        districtByDivisionData: action.payload.data,
        isLoading: false,
      };

    case ADD_UPAZILA_SUCCESS:
      return {
        ...state,
        upazila: [...state.upazila, action.payload],
        isLoading: false,
      };

    case ADD_UPAZILA_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STORE_UPAZILA_DATA:
      return {
        ...state,
        editUpazilaInfo: {
          ...state.editUpazilaInfo,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_UPAZILA_SUCCESS:
      return {
        ...state,
        upazilaData: state.upazila.map((upazila) =>
          upazila.id.toString() === action.payload.id.toString()
            ? { upazila, ...action.payload }
            : upazila
        ),
        loading: false,
      };

    case UPDATE_UPAZILA_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //parliamentary
    case GET_PARLIAMENTARY_VALUE:
      return {
        ...state,
        isLoading: true,
      };
      break;

    case GET_PARLIAMENTARY_VALUE_SUCCESS:
      return {
        ...state,
        parliamentaryData: action.payload.data,
        isLoading: false,
      };

    case ADD_PARLIAMENTARY_SUCCESS:
      return {
        ...state,
        parliamentary: [...state.parliamentary, action.payload],
        isLoading: false,
      };

    case ADD_PARLIAMENTARY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case STORE_PARLIAMENTARY_DATA:
      return {
        ...state,
        editParliamentaryInfo: action.payload,
      };
    case UPDATE_PARLIAMENTARY_SUCCESS:
      return {
        ...state,
        parliamentaryData: state.parliamentary.map((parliamentary) =>
          parliamentary.id.toString() === action.payload.id.toString()
            ? { parliamentary, ...action.payload }
            : parliamentary
        ),
        loading: false,
      };

    case UPDATE_PARLIAMENTARY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default GeoInformationReducer;
