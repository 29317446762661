import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import UpazilaView from "./UpazilaView";

const Upazila = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="Upazila"
      />

      <UpazilaView />
    </div>
  );
};

export default Upazila;
