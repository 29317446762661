import { forwardRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { IMG_API } from "../../helpers/api_helper";
import CustomTable from "../Layout/CustomTable";
import NoTableData from "../Layout/NoTableData";

const tableHead = [
  "Sl. No.",
  "Name",
  "Employee ID",
  "Role",
  "RS Name",
  "Division",
  "In Time",
  "Status",
  "Remarks",
  "Image",
];

const PrintAttendance = forwardRef((props, ref) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  let totalPageNumber = Math.ceil(
    props.attendance?.data?.data?.totalLength / handleList
  );
  console.log(
    "props.attendance?.data?.data?.todaygivenattandance",
    props.attendance?.data?.data?.todaygivenattandance
  );
  return (
    <div ref={ref}>
      <CustomTable
        paginationClass="paginationContainer text-right"
        data={[1, 2, 3]}
        pageNo={totalPageNumber}
        tableHead={tableHead}
        setCurrentPage={setCurrentPage}
        // isPagination
      >
        {props.attendanceLoading ? (
          <tr style={{ width: "100%" }}>
            <div
              className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
              style={{ width: "100%" }}
            >
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </tr>
        ) : props.attendance?.data?.data?.todaygivenattandance?.length > 0 ? (
          props.attendance?.data?.data?.todaygivenattandance?.map(
            (data, key) => (
              <tr>
                <td>
                  {" "}
                  {key +
                    (currentPage == 1
                      ? 0
                      : currentPage * handleList - handleList) +
                    1}
                </td>
                <td>{data?.name}</td>
                <td>{data?.employeeId}</td>
                <td>{data?.role}</td>
                <td>{data?.linemanager ? data?.linemanager : "N/A"}</td>
                <td>{data?.division ? data?.division : "N/A"}</td>
                <td>{data?.intime}</td>
                <td>
                  {data?.attanadancecheck
                    ? data?.isLate
                      ? "Late"
                      : "Present"
                    : "Absent"}
                </td>

                <td>{data?.remarks}</td>
                <td>
                  {" "}
                  {data?.image !== "N/A" ? (
                    <img
                      src={IMG_API + "/" + data?.image}
                      alt="user_photo"
                      style={{ width: "40px", height: "40px" }}
                      className="me-2"
                    />
                  ) : (
                    <>N/A</>
                  )}
                </td>
              </tr>
            )
          )
        ) : (
          <NoTableData
            colSpan="6"
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "300px", width: `100%` }}
          >
            <span>No data Available</span>
          </NoTableData>
        )}
      </CustomTable>
    </div>
  );
});

export default PrintAttendance;
