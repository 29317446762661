import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Input } from "reactstrap";
import * as XLSX from "xlsx";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { get } from "../../helpers/api_helper";
import { getUsers } from "../../store/actions";
import { getAttendance } from "../../store/Attendance/actions";
import PrintAttendance from "./PrintAttendance";
const tableHead = [
  "Sl. No.",
  "Name",
  "Employee ID",
  "Role",
  "In Time",
  "Status",
  "Image",
];
const Attendance = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [role, setRole] = useState("SO");
  const [loading, setLoading] = useState(false);
  const [soRsId, setSoRsId] = useState("All");
  const [lineManagerId, setLineManagerId] = useState("");
  const [status, setStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const { authtoken, attendance, attendanceLoading, users } = useSelector(
    (state) => ({
      authtoken: state.Login.token,
      attendance: state?.AttendanceReducer?.attendance,
      attendanceLoading: state?.AttendanceReducer?.attendanceLoading,
      users: state?.UserReducer?.users,
    })
  );
  useEffect(() => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.toDate = currentDate;
    dispatch(
      getAttendance(
        authtoken,
        lineManagerId,
        soRsId,
        fromDate,
        handleList,
        currentPage,
        status
      )
    );
  }, [fromDate, soRsId, handleList, currentPage, status]);
  let totalPageNumber = Math.ceil(
    attendance?.data?.data?.totalLength / handleList
  );
  const handleListData = (e) => {
    setHandleList(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleChangeDate = (e) => {
    // const value = convertDigitIn(e.target.value);
    const value = e.target.value;

    setCurrentDate(value);
  };
  const handleFromDate = (e) => {
    // const value = convertDigitIn(e.target.value);
    const value = e.target.value;

    setFromDate(value);
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
  };
  const handleUser = (value) => {
    if (value) {
      setSoRsId(value._id);
    } else {
      setSoRsId("All");
    }
  };

  const handleExcelDownload = async () => {
    let obj = {};
    obj.fromDate = fromDate;
    obj.toDate = currentDate;
    setLoading(true);
    let attendanceData = [];
    await get(
      `/attendance/attendance-report?userId=${soRsId}&status=${status}&dateid=${fromDate}`,
      {
        headers: { Authorization: `Bearer ${authtoken}` },
      }
    )
      .then((response) => {
        response?.todaygivenattandance?.forEach((attendance, index) => {
          let data = {};
          data.Name = attendance?.name;
          data.EmployeeId = attendance?.employeeId;
          data.Role = attendance?.role;
          data.RSName = attendance?.linemanager;
          data.Division = attendance?.division;
          data.InTime = attendance?.intime;
          // data.status = attendance?.isLate;
          // data.Date = attendance?.dateid;
          data.Status = attendance?.attanadancecheck
            ? attendance?.isLate
              ? "Late"
              : "Present"
            : "Absent";
          data.Remarks = attendance?.remarks;

          // data.Phone = attendance?.userId?.phoneNumber;
          attendanceData.push(data);
        });
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(attendanceData);
  };
  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Attendance.xlsx");
    setLoading(false);
  };
  useEffect(() => {
    dispatch(getUsers(authtoken, "All", 0, 0));
  }, []);

  let soRsUsers = [];
  if (users?.data?.data?.users.length > 0) {
    users?.data?.data?.users?.map((data, idx) => {
      if (data.role !== "ADMIN" && data.role !== "NCO") {
        soRsUsers.push({
          _id: data._id,
          name: data.name,
        });
      }
    });
  }

  return (
    <div>
      <Breadcrumb
        leftTitle="Attendance"
        rightTitle="Dashboard"
        pageTitle="Attendance"
      />
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb dflex-none">
          <h4>Attendance List</h4>
          <div className="all-user-inner-wrap dflex-none text-center">
            <div className="custom-margin-top">
              <button
                className="btn btn-success"
                onClick={handleExcelDownload}
                disabled={loading}
                style={{ marginTop: "8px", padding: "8px 0.75rem" }}
              >
                {loading ? "Downloading..." : "Download Excel"}
              </button>
            </div>{" "}
            <div>
              <ReactToPrint content={() => ref.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button
                      onClick={handlePrint}
                      style={{ marginTop: "8px", padding: "8px 0.75rem" }}
                      className="btn btn-info"
                    >
                      Print{" "}
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </div>{" "}
            {/* <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap mt-2">
                  <i className="fas fa-search"></i>
                  <input type="search" name="" id="" placeholder="Search..." />
                </div>
              </div>
            </div> */}
            <div className="single-select-field mt-2">
              <Input type="select" onChange={handleStatus}>
                <option value="All">All</option>
                <option value="Present">Present</option>
                <option value="Absent">Absent</option>
                <option value="Late">Late</option>
              </Input>
            </div>
            {/* <div className="">
              <Select
                className="mt-2 ms-2"
                classNamePrefix="select2-selection"
                placeholder="Select User"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                isClearable
                options={soRsUsers}
                onChange={handleUser}
              />
            </div> */}
            <div className="single-select-field">
              <Form.Group className="to-date-form">
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={handleFromDate}
                />
              </Form.Group>
            </div>
            {/* <span className="mt-3">To</span>
            <div className="single-select-field">
              <Form.Group className="mb-3 to-date-form">
                <Form.Control
                  type="date"
                  value={currentDate}
                  // value={moment(currentDate).format("YYYY-MM-DD")}
                  onChange={handleChangeDate}
                />
              </Form.Group>
            </div> */}
            {/* <div className="single-select-field">
              <Input type="select" onChange={handleListData}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </Input>
            </div> */}
          </div>
        </div>

        <PrintAttendance
          ref={ref}
          attendance={attendance}
          attendanceLoading={attendanceLoading}
        />
        {/* table end */}
      </div>
    </div>
  );
};

export default Attendance;
