import {
  GET_ALL_USER_ATTENDANCE_FAIL,
  GET_ALL_USER_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_FAIL,
  GET_ATTENDANCE_SUCCESS,
  GET_SINGLE_USER_ATTENDANCE_FAIL,
  GET_SINGLE_USER_ATTENDANCE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  attendance: [],
  allAttendance: [],
  singleAttendance: [],
  attendanceLoading: true,
};

const AttendanceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: action.payload,
        attendanceLoading: false,
      };
    case GET_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        attendanceLoading: true,
      };

    case GET_SINGLE_USER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        singleAttendance: action.payload,
        attendanceLoading: false,
      };
    case GET_SINGLE_USER_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        attendanceLoading: true,
      };
    case GET_ALL_USER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        allAttendance: action.payload,
        attendanceLoading: false,
      };
    case GET_ALL_USER_ATTENDANCE_FAIL:
      return {
        ...state,
        error: action.payload,
        attendanceLoading: true,
      };
    default:
      return state;
  }
};

export default AttendanceReducer;
