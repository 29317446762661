import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import DivisionVIew from "./DivisionVIew";

const Division = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="Create Division"
      />
      <DivisionVIew />
    </div>
  );
};

export default Division;
