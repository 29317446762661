import React from "react";
import { Container } from "react-bootstrap";
import "./footer.scss";
const Footer = () => {
  return (
    <div className="footer-main-area-wrap">
      <Container fluid>
        <div className="footer-inner-area-wrap">
          <p className="mb-2">2022 © aXs</p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
