import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  addNewParliamentary,
  getDistrictByDivision,
  getDistrictValue,
  getDivisionValue,
  getParliamentaryValue,
  storeParliamentaryData,
  updateParliamentary,
} from "../../store/actions";
import Pagination from "../Common/Pagination";

const ParliamentarySeatView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [parliamentary, setParliamentary] = useState("");
  const [createDivistion, setcreateDivistion] = useState(false);
  const [editDivistion, setEditDivistion] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(50);
  const [parliamentaryByDist, setParliamentaryByDist] = useState("");

  const {
    divisionData,
    loading,
    authtoken,
    editParliamentaryInfo,
    districtData,
    parliamentaryData,
    districtByDivisionData,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    editParliamentaryInfo: state.GeoInformationReducer.editParliamentaryInfo,
    districtByDivisionData: state.GeoInformationReducer.districtByDivisionData,
  }));

  //division list
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);

  //district list
  useEffect(() => {
    dispatch(getDistrictValue(authtoken, currentPage, pageRange));
  }, [currentPage, pageRange]);

  //Parliamentary list

  useEffect(() => {
    if (parliamentaryByDist) {
      dispatch(
        getParliamentaryValue(
          authtoken,
          currentPage,
          pageRange,
          parliamentaryByDist
        )
      );
    } else {
      dispatch(
        getParliamentaryValue(
          authtoken,
          currentPage,
          pageRange,
          parliamentaryByDist
        )
      );
    }
  }, [currentPage, pageRange, parliamentaryByDist]);

  let totalPageNumber = Math.ceil(parliamentaryData?.totalLength / pageRange);
  //Parliamentary list

  //add Parliamentary
  const onSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.name = parliamentary;
    obj.division = division;
    obj.district = district;
    dispatch(addNewParliamentary(obj, history, authtoken));
    setcreateDivistion(false);
  };
  //add Parliamentary

  //edit Parliamentary
  const onEditSubmit = (e, data) => {
    e.preventDefault();
    let obj = {};
    obj.name = parliamentary ? parliamentary : data.name;
    obj.division = division ? division : data.district.division._id;
    obj.district = district ? district : data.district._id;
    dispatch(updateParliamentary(data._id, obj, authtoken, history));
    setEditDivistion(false);
  };

  const handleEditParliamentary = (data) => {
    dispatch(storeParliamentaryData("singleParliamentary", data));
  };
  //filter
  const handleDivision = (data) => {
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  const handleDistrict = (value) => {
    if (!!value) {
      setParliamentaryByDist(value);
    } else {
      setParliamentaryByDist();
    }
  };
  return (
    <div>
      <Modal
        open={createDivistion}
        onClose={() => setcreateDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setDivision(e._id);
                  handleDivision({ name: e.name, _id: e._id });
                }}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Select District</Form.Label>
              <Select
                options={districtByDivisionData?.districts}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => setDistrict(e._id)}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type Parliamentary Seat</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Parliamentary seat"
                onChange={(e) => setParliamentary(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              Create Parliamentary
            </Button>
          </Form>
        </div>
      </Modal>{" "}
      <Modal
        open={editDivistion}
        onClose={() => setEditDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setDivision(e._id);
                  handleDivision({ name: e.name, _id: e._id });
                }}
                defaultValue={divisionData?.divisions?.filter(
                  (dta) =>
                    dta._id ===
                    editParliamentaryInfo?.data?.district?.division?._id
                )}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Select District</Form.Label>
              <Select
                options={districtByDivisionData?.districts}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => setDistrict(e._id)}
                defaultValue={districtData?.districts?.filter(
                  (dta) =>
                    dta._id === editParliamentaryInfo?.data?.district?._id
                )}
                required
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type Parliamentary Seat</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type Parliamentary Seat"
                onChange={(e) => setParliamentary(e.target.value)}
                defaultValue={editParliamentaryInfo?.data?.name}
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onEditSubmit(e, editParliamentaryInfo?.data)}
            >
              Update Parliamentary
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Parliamentary seat</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input type="search" name="" id="" placeholder="Search..." />
                </div>
              </div>
            </div>
            <div className="filter-box">
              <Select
                options={divisionData?.divisions}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                placeholder="Select Division"
                isClearable
                onChange={handleDivision}
              />
            </div>{" "}
            <div className="filter-box">
              <Select
                options={districtByDivisionData?.districts}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                placeholder="Select District"
                onChange={handleDistrict}
                isClearable
              />
            </div>{" "}
            <div className="filter-box">
              <Form.Select
                aria-label="10"
                onChange={(e) => {
                  setPageRange(e.target.value);
                }}
                // value={pageRange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
            </div>{" "}
            <div className="filter-box add-division-btn">
              <button
                className="btn btn-success"
                onClick={() => setcreateDivistion(true)}
              >
                Create Parliamentary Seat
              </button>
            </div>
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Division </th>
                <th>District </th>
                <th>Parliamentary Seat </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {parliamentaryData?.electoralSeats?.length > 0 ? (
                parliamentaryData?.electoralSeats.map((data, idx) => (
                  <tr key={idx}>
                    <td>
                      {idx +
                        (currentPage == 1
                          ? 0
                          : currentPage * pageRange - pageRange) +
                        1}
                    </td>
                    <td>{data?.district?.division?.name}</td>

                    <td>{data?.district?.name}</td>
                    <td>{data?.name}</td>
                    <td>
                      <Button
                        variant="info"
                        className="btn-sm"
                        onClick={() => {
                          setEditDivistion(true);
                          handleEditParliamentary(data);
                        }}
                      >
                        <i class="fa-solid fa-eye-dropper"></i>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ParliamentarySeatView;
