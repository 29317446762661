import { Redirect } from "react-router-dom";
import Attendance from "../Components/Attendance/Attendance";
import AttendanceSummary from "../Components/Attendance/AttendanceSummary";
import Attendence from "../Components/Attendence/Attendence";
import Login from "../Components/Authentication/Login";
import AddInstitute from "../Components/CreateInstitute/AddInstitute";
import EditInstitute from "../Components/CreateInstitute/EditInstitute";
import Dashboard from "../Components/Dashboard/Dashboard";
import District from "../Components/District/District";
import Division from "../Components/Division/Division";
import InstituteList from "../Components/InstituteList/InstituteList";
import AddIssue from "../Components/IssueList/AddIssue";
import EditIssue from "../Components/IssueList/EditIssue";
import IssueList from "../Components/IssueList/IssueList";
import ParliamentarySeat from "../Components/ParliamentarySeat/ParliamentarySeat";
import ProfileMain from "../Components/ProfilePage/ProfileMain";
import Upazila from "../Components/Upazila/Upazila";
import AssignInstitute from "./../Components/AssignInstitute/AssignInstitute";
import Pjp from "./../Components/pjp/Pjp";
import UserList from "./../Components/UserList/UserList";

const openRoute = [
  // { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  // { path: "/register", component: Register },
];

const protectedRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admindashboard" />,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/admindashboard",
    component: Dashboard,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/institute-list",
    component: InstituteList,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/institute-list",
    component: InstituteList,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },

  {
    path: "/attendence",
    component: Attendence,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },

  {
    path: "/create-institute",
    component: AddInstitute,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-institute/:id",
    component: EditInstitute,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },

  {
    path: "/division",
    component: Division,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/district",
    component: District,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/upazila",
    component: Upazila,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/parliamentary-seat",
    component: ParliamentarySeat,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/user-list",
    component: UserList,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/assign-institute",
    component: AssignInstitute,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/issue-list",
    component: IssueList,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/add-issue",
    component: AddIssue,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/edit-issue/:id",
    component: EditIssue,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/profile",
    component: ProfileMain,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  //PJP
  {
    path: "/PJP",
    component: Pjp,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/attendance",
    component: Attendance,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
  {
    path: "/attendance-summary",
    component: AttendanceSummary,
    exact: true,
    roles: ["ADMIN", "ORG"],
  },
];

export { openRoute, protectedRoute };
