import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import user1 from "./img/User.png";
const AllAttendence = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div className="allAttendence-main-wrap">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="attendence-modal-data-view-vbjhgbn">
          <div className="attendance-profile-view-image-area">
            <span>
              <img src={user1} alt="" />
            </span>
            <span>
              <h6>Minnie Walter</h6>
              <p>Executive</p>
            </span>
          </div>
          <Table striped borderless hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>20/02/2022</td>
                <td>10:00 AM</td>
              </tr>{" "}
              <tr>
                <td>21/02/2022</td>
                <td>10:00 AM</td>
              </tr>{" "}
              <tr>
                <td>22/02/2022</td>
                <td>10:00 AM</td>
              </tr>{" "}
              <tr>
                <td>23/02/2022</td>
                <td>10:00 AM</td>
              </tr>{" "}
              <tr>
                <td>24/02/2022</td>
                <td>10:00 AM</td>
              </tr>{" "}
              <tr>
                <td>25/02/2022</td>
                <td>10:00 AM</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
      <Row>
        <Col lg={3}>
          <div className="single-attend-persion">
            <img src={user1} alt="" />
            <h5>Minnie Walter</h5>
            <p>Executive</p>
            <button onClick={onOpenModal}>Attendance</button>
          </div>
        </Col>{" "}
      </Row>
    </div>
  );
};

export default AllAttendence;
