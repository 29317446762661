import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
    getIssueStatusData,
    getPendingIssueBySOData,
    getPendingIssueByInstituteData,
    getDashBoardAttendanceData
} from "../../helpers/api_helper.js";
import {
    getIssueStatusSuccess,
    getIssueStatusFail,
    getPendingIssueBySOSuccess,
    getPendingIssueBySOFail,
    getPendingIssueByInstituteSuccess,
    getPendingIssueByInstituteFail,
    getDashboardAttendanceSuccess,
    getDashboardAttendanceFail


} from "./actions";
import {
    GET_ISSUE_STATUS,
    GET_PENDING_ISSUE_BY_SO,
    GET_PENDING_ISSUE_BY_INSTITUTE,
    GET_DASHBOARD_ATTENDANCE
} from "./actionTypes";

function* fetchIssueStatus({ payload: { authtoken, startDate, endDate} }) {
  try {
    const response = yield call(getIssueStatusData, authtoken, startDate, endDate);
    yield put(getIssueStatusSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getIssueStatusFail(error));
    console.log("hi");
  }
}
function* fetchPendingIssueBySO({ payload: { authtoken, startDate, endDate } }) {
    try {
      const response = yield call(getPendingIssueBySOData, authtoken, startDate, endDate);
      yield put(getPendingIssueBySOSuccess(response));
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getPendingIssueBySOFail(error));
      console.log("hi");
    }
  }
  function* fetchPendingIssueByInstitute({ payload: { authtoken, startDate, endDate } }) {
    try {
      const response = yield call(getPendingIssueByInstituteData, authtoken, startDate, endDate);
      yield put(getPendingIssueByInstituteSuccess(response));
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getPendingIssueByInstituteFail(error));
      console.log("hi");
    }
  }
  function* fetchDashboardAttendance({ payload: { authtoken } }) {
    try {
      const response = yield call(getDashBoardAttendanceData, authtoken);
      yield put(getDashboardAttendanceSuccess(response));
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDashboardAttendanceFail(error));
      console.log("hi");
    }
  }


function* DashboardSaga() {

  yield takeEvery(GET_ISSUE_STATUS, fetchIssueStatus);
  yield takeEvery(GET_PENDING_ISSUE_BY_SO, fetchPendingIssueBySO);
  yield takeEvery(GET_PENDING_ISSUE_BY_INSTITUTE, fetchPendingIssueByInstitute);
  yield takeEvery(GET_DASHBOARD_ATTENDANCE, fetchDashboardAttendance);
}
export default DashboardSaga;
