import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import excelFormat from "../../Components/excel/institute.xlsx";
import { toaster } from "../../Custom Helper/Custom/Excel toaster";
import { deleteData, IMG_API, post, postData } from "../../helpers/api_helper";
import useExcelReader from "../../Hooks/useExcelReader";
import {
  addInstitute,
  getDistrictByDivision,
  getDivisionValue,
  getParliamentaryValue,
  getUpazilaValue,
} from "../../store/actions";
import AddFileUpload from "../Common/AddFileUpload";
import "./users.scss";

const AddInstitutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [addUser, setAddUser] = useState("");
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [upazila, setUpazila] = useState("");
  const [parliamentary, setParliamentary] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  const [parliamentaryByDist, setParliamentaryByDist] = useState("");
  const [sequentialNumber, setSequentialNumber] = useState("");
  const [nameEnglish, setNameEnglish] = useState("");
  const [nameBangali, setNameBangali] = useState("");
  const [eiinNumber, setEiinNumber] = useState("");
  const [address, setAddress] = useState("");
  const [management, setManagement] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [level, setLevel] = useState("");
  const [studentType, setStudentType] = useState("");
  const [totalStudent, setTotalStudent] = useState("");
  const [femaleStudent, setFemaleStudent] = useState("");
  const [totalTeacher, setTotalTeacher] = useState("");
  const [femaleTeacher, setFemaleTeacher] = useState("");
  const [sizeOfCategory, setSizeOfCategory] = useState("");
  const [headOfInstitute, setHeadOfInstitute] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternativeContactNumber, setAlternativeContactNumber] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [location, setLocation] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [openExcel, setOpenExcel] = useState(false);
  const { items, readExcel } = useExcelReader();
  console.log(`🦄 ~ file: Dashboard.js ~ line 21 ~ Dashboard ~ items`, items);
  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [openExel, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpenExcel(!openExcel);
  const { file, setFile } = useState("");
  const [fieldValue, setFieldValue] = useState("");

  const managements = [
    { value: "GOVERNMENT", name: "GOVERNMENT" },
    { value: "NON-GOVERNMENT", name: "NON-GOVERNMENT" },
  ];
  const instituteTypes = [
    { value: "School", name: "School" },
    { value: "College", name: "College" },
    { value: "School & College", name: "School & College" },
  ];
  const studentTypes = [
    { value: "BOYS", name: "BOYS" },
    { value: "GIRLS", name: "GIRLS" },
    { value: "CO-EDUCATION JOINT", name: "CO-EDUCATION JOINT" },
  ];
  const levels = [{ value: "Secondary", name: "Secondary" }];

  const {
    divisionData,
    loading,
    authtoken,
    districtData,
    parliamentaryData,
    districtByDivisionData,
    upazilaData,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    upazilaData: state.GeoInformationReducer.upazilaData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    districtByDivisionData: state.GeoInformationReducer.districtByDivisionData,
  }));
  //division list
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);

  //upazila list
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    } else {
      dispatch(getUpazilaValue({}));
    }
  }, [upazilaByDist]);

  //Parliamentary list

  useEffect(() => {
    if (parliamentaryByDist) {
      dispatch(getParliamentaryValue(authtoken, 0, 0, parliamentaryByDist));
    } else {
      dispatch(getParliamentaryValue({}));
    }
  }, [parliamentaryByDist]);

  //filter
  const handleDivision = (data) => {
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  const handleDistrict = (value) => {
    if (!!value) {
      setUpazilaByDist(value);
      setParliamentaryByDist(value);
    } else {
      setUpazilaByDist();
      setParliamentaryByDist();
    }
  };

  //add upazila
  const handleValidSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.eiinNumber = eiinNumber;
    obj.sequentialNumber = sequentialNumber;
    obj.division = division;
    obj.district = district;
    obj.upazila = upazila;
    // obj.electoralSeat = parliamentary;
    obj.nameEnglish = nameEnglish;
    obj.nameBangali = nameBangali;
    obj.management = management;
    obj.location = location;
    obj.address = address;
    obj.instituteType = instituteType;
    obj.level = level;
    obj.studentType = studentType;
    obj.totalStudent = totalStudent;
    obj.femaleStudent = femaleStudent;
    obj.totalTeacher = totalTeacher;
    obj.femaleTeacher = femaleTeacher;
    obj.sizeOfCategory = sizeOfCategory;
    obj.headOfInstitute = headOfInstitute;
    obj.contactNumber = contactNumber;
    obj.alternativeContactNumber = alternativeContactNumber;
    obj.lat = lat;
    obj.long = long;
    obj.image = fieldValue;

    dispatch(addInstitute(obj, history, authtoken));
  };
  //add upazila
  const handleSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      const data = {
        eiinNumber: element?.EIIN,
        sequentialNumber: element?.SLNo,
        division: element?.DIVISION,
        district: element?.DISTRICT,
        upazila: element?.Upazila,
        address: element?.LOCATION,
        nameEnglish: element?.NAME_OF_INSTITUTION,
        management: element?.MANAGEMENT,
        level: element?.LEVEL,
        studentType: element?.STUDENT_TYPE,
        totalStudent: element?.TOT_STU,
        femaleStudent: element?.FEM_STU,
        totalTeacher: element?.TOT_TEACHER,
        femaleTeacher: element?.FEM_TEACHER,
        sizeOfCategory: element?.SIZE_OF_CATEGORY,
        headOfInstitute: element?.INSTITUTE_HEAD_NAME_BANGLA,
        contactNumber: element?.MOBILE_NO,
        alternativeContactNumber: element?.ALTERNATIVE_MOBILE_NO,
        lat: element?.LAT,
        long: element?.LONG,
        SO_Name: element?.SO_Name,
      };

      await post("/institute/excel-institute-list", data, {
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          setResults((prev) => [...prev, { status: "failed" }]);
        });

      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster("success", "All data upload successful");
      }
    }
  };
  const tableHead = items?.[0] || {};
  const handleDivisionData = (value) => {
    if (value) {
      setDivision(value._id);
      handleDivision({ name: value.name, _id: value._id });
    } else {
      setDivision("");
    }
  };
  const handleDistrictData = (value) => {
    if (value) {
      setDistrict(value._id);
      handleDistrict({ name: value.name, _id: value._id });
    } else {
      setDistrict("");
    }
  };
  const handleUpazilaData = (value) => {
    if (value) {
      setUpazila(value._id);
    } else {
      setUpazila("");
    }
  };
  const handleParliamentData = (value) => {
    if (value) {
      setParliamentary(value._id);
    } else {
      setParliamentary("");
    }
  };
  const handleManagement = (value) => {
    if (value) {
      setManagement(value?.value);
    } else {
      setManagement("");
    }
  };
  const handleInstituition = (value) => {
    if (value) {
      setInstituteType(value?.value);
    } else {
      setInstituteType("");
    }
  };
  const handleLevel = (value) => {
    if (value) {
      setLevel(value?.value);
    } else {
      setLevel("");
    }
  };
  const handleStudent = (value) => {
    if (value) {
      setStudentType(value?.value);
    } else {
      setStudentType("");
    }
  };

  // image upload setFieldValue, single
  const uploadImage = async (file) => {
    const url = `institute/image-uplaod`;
    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", file.name);
    try {
      const response = await postData(formData, authtoken);

      if (response.data.image) {
        setFieldValue(response.data.image.url);
        // setFieldValue(single ? name : name + ".id", response.image._id || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const ref = useRef();
  const removeImage = async () => {
    try {
      const response = await deleteData(authtoken, fieldValue);

      if (response) {
        console.log("responseeeee", response);
        setFieldValue("");
        ref.current.value = "";
        toast("🦄 Image deleted successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Breadcrumb
        leftTitle="Add new institute"
        rightTitle="Dashboard"
        pageTitle="Institute / Add institute"
      />
      <div className="add-user-main-wrap">
        <Form
          className="format-upload-form-main-area- mb-5"
          onSubmit={(e) => handleValidSubmit(e)}
        >
          <Row>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Sequential Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Sequential Number"
                  onChange={(e) => setSequentialNumber(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name English</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Institute Name English"
                  onChange={(e) => setNameEnglish(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name Bangali </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Institute Name Bangali"
                  onChange={(e) => setNameBangali(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>EIIN Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type EIIN Number"
                  onChange={(e) => setEiinNumber(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Division</Form.Label>
                <Select
                  options={divisionData?.divisions}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Division"
                  isClearable
                  onChange={handleDivisionData}
                  required="true"
                ></Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>District </Form.Label>
                <Select
                  options={districtByDivisionData?.districts}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select District"
                  onChange={handleDistrictData}
                  isClearable
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Upazila </Form.Label>
                <Select
                  options={upazilaData?.upazilas}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Upazila"
                  isClearable
                  onChange={handleUpazilaData}
                ></Select>
              </Form.Group>
            </Col>{" "}
            {/* <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Parliamentary Seat </Form.Label>
                <Select
                  options={parliamentaryData?.electoralSeats}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  placeholder="Select Parliamentary"
                  isClearable
                  onChange={handleParliamentData}
                ></Select>
              </Form.Group>
            </Col>{" "} */}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Management</Form.Label>
                <Select
                  options={managements}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={handleManagement}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Location </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type user name"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Type </Form.Label>
                <Select
                  options={instituteTypes}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={handleInstituition}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Level </Form.Label>
                <Select
                  options={levels}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={handleLevel}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Student Type </Form.Label>
                <Select
                  options={studentTypes}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  onChange={handleStudent}
                ></Select>
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Total Student</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Total Student"
                  onChange={(e) => setTotalStudent(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Female Student</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Number of  Female Student"
                  onChange={(e) => setFemaleStudent(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Total Teacher</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Total Teacher"
                  onChange={(e) => setTotalTeacher(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Female Teacher</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Number of Female Teacher"
                  onChange={(e) => setFemaleTeacher(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Size Of Category</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Size Of Category"
                  onChange={(e) => setSizeOfCategory(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Head Of Institute</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Head Of Institute"
                  onChange={(e) => setHeadOfInstitute(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Contact Number"
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Alternative Contact Number</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Alternative Contact Number"
                  onChange={(e) => setAlternativeContactNumber(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Lat </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Lat"
                  onChange={(e) => setLat(e.target.value)}
                />
              </Form.Group>
            </Col>{" "}
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Long </Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Type Long"
                  onChange={(e) => setLong(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Image </Form.Label>
                <Form.Control
                  type="file"
                  ref={ref}
                  onChange={(e) => {
                    uploadImage(e.target.files[0]);
                  }}
                  disabled={fieldValue ? true : false}
                />
                {fieldValue ? (
                  <div
                    className="position-absolute"
                    style={{
                      width: "initial",
                      right: "272px",
                    }}
                    onClick={() => removeImage()}
                  >
                    <button
                      className="btn-sm removeShadow"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      type="button"
                    >
                      <i
                        className="bx bx-trash text-danger"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                <div className="text-center mt-4">
                  {fieldValue ? (
                    <img
                      src={`${IMG_API}/${fieldValue}`}
                      alt="image"
                      style={{ width: "75px", height: "75px" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <div className="add-user-button-area-wrap">
            <div className="create-new-format-btn-area-wrap- text-end">
              <button className="btn btn-success" type="submit">
                Create Institute
              </button>
            </div>
          </div>
        </Form>
      </div>{" "}
      {/* <div className="add-user-main-wrap">
        <Form className="format-upload-form-main-area-zkjdh">
          <Row>
            <Col lg={12}>
              <div
                className="user-data-input-area-uygfvbhjuihyghjnk"
                // style={{ backgroundImage: `url(${userfileInputBg})` }}
              >
                <form className="mb-3">
                  <label htmlFor="uploadUserFile">
                    <img src={userfileInputBg2} alt="" />
                    <p> Upload user image</p>
                  </label>
                  <input type="file" placeholder="" id="uploadUserFile" />
                </form>
              </div>
            </Col>
          </Row>
        </Form>
      </div> */}
      <div className="d-flex align-items-center justify-content-between mt-5 mb-4">
        <div className="mt-3">
          <h6>Upload Excel</h6>
        </div>
        <div className="ml-auto">
          <a href={excelFormat}>
            <button className="btn btn-secondary">
              <i class="fa fa-download me-1" aria-hidden="true"></i>Download
              Format
            </button>
          </a>
        </div>
      </div>
      <AddFileUpload
        setSelectedFiles={setSelectedFiles}
        acceptFile={
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        handleFile={(file) => {
          readExcel(file);
        }}
      />
      <Modal
        open={openExcel}
        toggle={toggle}
        size="lg"
        onClose={() => setOpenExcel(!openExcel)}
      >
        {/* <ModalBody> */}
        <Table className="table table-responsive">
          <thead>
            <tr>
              <th>No.</th>
              {Object.keys(tableHead)?.map((item, idx) => (
                <th key={idx}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items?.map((item, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item?.DIVISION}</td>
                <td>{item?.DISTRICT}</td>
                <td>{item?.UPAZILA}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* </ModalBody> */}
      </Modal>
      {items?.length > 0 ? (
        <div className="mt-4 text-center">
          <button className="btn btn-primary me-2" onClick={toggle}>
            Preview
          </button>
          <button
            disabled={adding}
            className="btn btn-primary "
            onClick={handleSubmit}
          >
            {adding ? "Submitting..." : "Submit"}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default AddInstitutes;
