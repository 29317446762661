import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import ParliamentarySeatView from "./ParliamentarySeatView";

const ParliamentarySeat = () => {
  return (
    <div>
      <Breadcrumb
        leftTitle="Dashboard"
        rightTitle="Dashboard"
        pageTitle="Parliamentary Seat"
      />

      <ParliamentarySeatView />
    </div>
  );
};

export default ParliamentarySeat;
