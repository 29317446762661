import React from "react";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import AllAttendence from "./AllAttendence";
import "./attendence.scss";
const Attendence = () => {
  return (
    <div>
      <Breadcrumb leftTitle="User" rightTitle="Dashboard" pageTitle="User" />
      <AllAttendence />
    </div>
  );
};

export default Attendence;
