import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  getAllAttendanceData,
  getAttendanceData,
  getSingleAttendanceData,
} from "../../helpers/api_helper";
import {
  getAllUserAttendanceFail,
  getAllUserAttendanceSuccess,
  getAttendanceFail,
  getAttendanceSuccess,
  getSingleUserAttendanceFail,
  getSingleUserAttendanceSuccess,
} from "./actions";
import {
  GET_ALL_USER_ATTENDANCE,
  GET_ATTENDANCE,
  GET_SINGLE_USER_ATTENDANCE,
} from "./actionTypes";

function* fetchAttendance({
  payload: {
    authtoken,
    lineManagerId,
    userId,
    fromDate,
    handleList,
    currentPage,
    status,
  },
}) {
  try {
    delay(500);

    const response = yield call(
      getAttendanceData,
      authtoken,
      lineManagerId,
      userId,
      fromDate,
      handleList,
      currentPage,
      status
    );
    console.log("fetchAttendance", response);
    yield put(getAttendanceSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getAttendanceFail(error));
  }
}

function* fetchSingleAttendance({
  payload: { authtoken, fromDate, toDate, userId },
}) {
  try {
    delay(500);

    const response = yield call(
      getSingleAttendanceData,
      authtoken,
      fromDate,
      toDate,
      userId
    );
    console.log("fetchAttendance", response);
    yield put(getSingleUserAttendanceSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getSingleUserAttendanceFail(error));
  }
}

function* fetchAllAttendance({ payload: { authtoken, fromDate, toDate } }) {
  try {
    delay(500);

    const response = yield call(
      getAllAttendanceData,
      authtoken,
      fromDate,
      toDate
    );
    console.log("fetchAttendance", response);
    yield put(getAllUserAttendanceSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getAllUserAttendanceFail(error));
  }
}

function* AttendanceSaga() {
  yield takeEvery(GET_ATTENDANCE, fetchAttendance);
  yield takeEvery(GET_SINGLE_USER_ATTENDANCE, fetchSingleAttendance);
  yield takeEvery(GET_ALL_USER_ATTENDANCE, fetchAllAttendance);
}

export default AttendanceSaga;
