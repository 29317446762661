import { combineReducers } from "redux";
import AttendanceReducer from "./Attendance/reducers";
import DashboardReducer from "./Dashboard/reducers";
import ForgetPassword from "./ForgetPassword/reducer";
import GeoInformationReducer from "./GeoInformation/reducers";
import InstituteReducer from "./Instituition/reducers";
import IssueReducer from "./Issue/reducer";
import Login from "./login/reducer";
import loginError from "./loginError/reducer";
import PjpReducer from "./pjp/reducers";
import registration from "./registration/reducer";
import UserReducer from "./User/reducers";
const rootReducer = combineReducers({
  // recruitReducer,
  // comparativeAnalysisReducer,
  Login,
  // adminDashboardReducer,
  // AdminAuthorReducer,
  registration,
  UserReducer,
  // courseReducer,
  // cartReducer,
  // academicAreaReducer,
  loginError,
  ForgetPassword,
  //updateAccountReducer
  GeoInformationReducer,
  InstituteReducer,
  IssueReducer,
  PjpReducer,
  AttendanceReducer,
  AttendanceReducer,
  DashboardReducer,
});

export default rootReducer;
