import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getInstituteBySO, getIssue } from "../../store/Issue/actions";
import { getSuperVisor } from "../../store/User/actions";
import IssueListView from "./IssueListView";
const IssueFiltering = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const [SO, SetSO] = useState("");
  const [issueType, setIssueType] = useState("");
  const [status, setStatus] = useState("Pending");
  const [institute, setInstitute] = useState("");

  const [issueStartDate, setIssueStartDate] = useState("All");
  const [issueEndDate, setIssueEndDate] = useState("All");
  const options = [{ value: "Division", label: "Division" }];
  const statusUpdate = [
    { value: "Pending", label: "Pending" },
    { value: "Solved", label: "Solved" },
  ];
  const issueTypeList = [
    { value: "Technical", label: "Technical" },
    { value: "Non-Technical", label: "Non-Technical" },
  ];
  const {
    authtoken,
    superVisor,
    superVisorLoading,
    instituteBySOData,
    instituteBySODataLoading,
    issueList,
    issueLimitation,
    issueListLoading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    superVisor: state?.UserReducer?.superVisor,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    instituteBySOData: state?.IssueReducer?.instituteBySOData,
    issueList: state?.IssueReducer?.issueList,
    issueLimitation: state?.IssueReducer?.issueLimitation,
    instituteBySODataLoading: state?.IssueReducer?.instituteBySODataLoading,
    issueListLoading: state?.IssueReducer?.issueListLoading,
  }));
  let totalLength = issueList?.data?.totalLength;
  let totalPageNumber = Math.ceil(totalLength / issueLimitation);

  useEffect(() => {
    dispatch(getSuperVisor(authtoken, "SO", 0, 0));
  }, []);
  var obj2 = {
    userId: SO,
    institute: institute,
    issueType: issueType,
    fromDate: issueStartDate,
    toDate: issueEndDate,
    status: status,
    limit: "",
    pageNo: "",
  };
  var obj = {
    userId: SO,
    institute: institute,
    issueType: issueType,
    fromDate: issueStartDate,
    toDate: issueEndDate,
    status: status,
    limit: issueLimitation || 10,
    pageNo: currentPage,
  };
  useEffect(() => {
    console.log("obj", obj);
    dispatch(getIssue(obj, history, authtoken));
  }, [
    SO,
    institute,
    issueType,
    issueStartDate,
    issueEndDate,
    issueLimitation,
    status,
    currentPage,
  ]);

  const handleSO = (data) => {
    if (data) {
      SetSO(data._id);
      dispatch(getInstituteBySO(authtoken, data._id));
    } else {
      SetSO("");
    }
  };
  let instituitionList;
  if (!instituteBySODataLoading) {
    instituitionList = instituteBySOData?.data?.data;
  }

  const handleIssue = (data) => {
    if (data) {
      setIssueType(data.value);
    } else {
      setIssueType("");
    }
  };
  const handleStatus = (data) => {
    if (data) {
      setStatus(data.value);
    } else {
      setIssueType("");
    }
  };
  const handleInstitute = (data) => {
    if (data) {
      setInstitute(data._id);
    } else {
      setInstitute("");
    }
  };
  function convertDigitIn(str) {
    return str.split("-").reverse().join("-");
  }
  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setIssueStartDate(moment(e.target.value).format("YYYY-MM-DD"));
  };
  const handleEndDate = (e) => {
    console.log("end", e.target.value);
    const value = e.target.value;
    setIssueEndDate(moment(e.target.value).format("YYYY-MM-DD"));
  };
  return (
    <>
      <div className="user-header-top-main-area-wrap">
        <Row>
          <Col lg={4}>
            <div className="single-select-field">
              <Select
                // name="linemanager"
                cacheOptions
                // isDisabled={isDisabled}
                placeholder="Select SO"
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                options={superVisor?.data?.data?.users}
                defaultOptions
                isClearable
                onChange={handleSO}
              />
            </div>
          </Col>{" "}
          <Col lg={4}>
            <div className="single-select-field">
              <Select
                // name="linemanager"
                placeholder="Select Institute"
                options={instituteBySOData?.data?.data?.instituteList}
                getOptionLabel={(e) => e.nameEnglish}
                getOptionValue={(e) => e._id}
                isClearable
                cacheOptions
                onChange={handleInstitute}
              />
            </div>
          </Col>{" "}
          <Col lg={4}>
            <div className="single-select-field">
              <Select
                // name="linemanager"
                placeholder="Select Issue Type"
                options={issueTypeList}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                isClearable
                cacheOptions
                onChange={handleIssue}
              />
            </div>
          </Col>{" "}
          <Col lg={4}>
            <div className="single-select-field">
              <Form.Group className="mb-3 to-date-form">
                <Form.Control
                  type="date"
                  // value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleStartDate}
                />
              </Form.Group>
            </div>
          </Col>{" "}
          <Col lg={4}>
            <div className="single-select-field">
              <Form.Group className="mb-3 to-date-form">
                <Form.Control
                  type="date"
                  //  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleEndDate}
                />
              </Form.Group>
            </div>
          </Col>{" "}
          <Col lg={4}>
            <div className="single-select-field">
              <Select
                placeholder="Select Status"
                options={statusUpdate}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                isClearable
                cacheOptions
                onChange={handleStatus}
              />
            </div>
          </Col>{" "}
        </Row>
      </div>
      {issueListLoading ? (
        <div className="text-center mt-5 pt-5">
          <p>Loading</p>
        </div>
      ) : (
        <IssueListView
          issueList={issueList}
          obj={obj}
          obj2={obj2}
        ></IssueListView>
      )}
    </>
  );
};
export default IssueFiltering;
