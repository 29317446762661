import { toast } from "react-toastify";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import {
  axiosGetSingleInstitute,
  getInstituteData,
  getSearchPjpData,
  postInstitutenData,
  updateInstituteData,
  userAssignInstitute,
} from "../../helpers/api_helper.js";
import {
  addInstituteFail,
  assignInstituteFail,
  assignInstituteSuccess,
  getInstitutionFail,
  getInstitutionSuccess,
  getSearchPjpFail,
  getSearchPjpSuccess,
  getSingleInstitutionSuccess,
  updateInstituteFail,
} from "./actions";
import {
  ADD_INSTITUTE,
  GET_ALL_INSTITUTE,
  GET_SINGLE_INSTITUTE,
  SEARCH_PJP,
  UPDATE_INSTITUTE,
  USER_ASSIGN_INSTITUTE,
} from "./actionTypes";

function* fetchInstitute({ payload: { data, history, authtoken } }) {
  console.log("data", data);
  console.log("authtoken", authtoken);
  try {
    const response = yield call(getInstituteData, data, authtoken);
    console.log("hi");
    console.log(response);
    yield put(getInstitutionSuccess(response));
  } catch (error) {
    if (!error.response) {
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(getInstitutionFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}

//GET SINGLE INSTITUTE

const asyncGetSingleInstitute = async (authtoken, instituteId) => {
  try {
    const response = await axiosGetSingleInstitute(authtoken, instituteId);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetSingleInstitute({ payload: { authtoken, instituteId } }) {
  try {
    const response = yield call(
      asyncGetSingleInstitute,
      authtoken,
      instituteId
    );
    yield put(getSingleInstitutionSuccess(response.data));
  } catch (error) {}
}

//ADD INSTITUTE
//async
const asyncPostInstituteData = async (data, authtoken) => {
  try {
    const response = await postInstitutenData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddInstitute({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostInstituteData, data, authtoken);
    if (response.status === "success") {
      toast("🦄 Institute added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/institute-list");
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error) {
    yield put(addInstituteFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
//update institute

function* onUpdateInstitute({ payload: { id, data, token, history } }) {
  try {
    const response = yield call(updateInstituteData, id, data, token);
    toast("🦄 Institute updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (response.status === 200) {
      history.push("/institute-list");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateInstituteFail(error));
  }
}
function* assignInstitute({ payload: { data, history, authtoken } }) {
  console.log("daaa", data);
  console.log(authtoken);
  try {
    const response = yield call(userAssignInstitute, data, authtoken);
    console.log("hi");
    console.log(response);
    yield put(assignInstituteSuccess(response));
    toast("🦄 Institute Assigned to User!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // history.push("/details-product")
  } catch (error) {
    if (!error.response) {
      toast("🦄 Institute Assigned to User!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(assignInstituteFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}
function* fetchSearchPjp({ payload: { authtoken, data } }) {
  try {
    delay(500);
    const response = yield call(getSearchPjpData, authtoken, data);
    yield put(getSearchPjpSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getSearchPjpFail(error));
    console.log("hi");
  }
}

function* InstituteSaga() {
  yield takeEvery(GET_ALL_INSTITUTE, fetchInstitute);
  yield takeEvery(GET_SINGLE_INSTITUTE, workerGetSingleInstitute);
  yield takeEvery(ADD_INSTITUTE, onAddInstitute);
  yield takeEvery(UPDATE_INSTITUTE, onUpdateInstitute);
  yield takeEvery(USER_ASSIGN_INSTITUTE, assignInstitute);
  yield takeEvery(SEARCH_PJP, fetchSearchPjp);
}

export default InstituteSaga;
