import axios from "axios";
// export const REACT_APP_API = `https://api-staging.axsbd.com/api/v1`;
export const REACT_APP_API = `https://api.axsbd.com/api/v1`;
export const IMG_API = `https://production-issue-tracker.sgp1.digitaloceanspaces.com`;
// export const IMG_API = `https://staging-issue-tracker.sgp1.digitaloceanspaces.com`;
//user account managemnet
export const axiosUserLogin = async (employeeId, password) =>
  await axios.post(`${REACT_APP_API}/users/login`, { employeeId, password });
// export const axiosLoginUser = async (email, password) =>
// await axios.post(`${API_URL}/users/login`, { email, password })
//Teacher Registration

const axiosApi = axios.create({
  baseURL: REACT_APP_API,
});
export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((e) => console.log("error", e));
}
export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export const axiosTeacherRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-teacher`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,
    instituteName,
    password,
    confirmPassword,
  });
//Student Registration
export const axiosStudentRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-student`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,
    instituteName,
    password,
    confirmPassword,
  });
//reset password
export const axiosResetPassword = async (
  accountActivateToken,
  email,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/user/account-verify`, {
    accountActivateToken,
    email,
    password,
    confirmPassword,
  });
// //forget password
export const axiosForgetPassword = async (email) =>
  await axios.patch(`${REACT_APP_API}/forget-password`, { email });
//update profile
export const axiosUpdateProfile = async (
  authtoken,
  firstName,
  lastName,
  phoneNumber
) =>
  await axios.patch(
    `${REACT_APP_API}/update-profile`,
    { firstName, lastName, phoneNumber },
    { headers: { authtoken } }
  );
//update password
export const axiosUpdatePassword = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/update-password`,
    { currentPassword, password, confirmPassword },
    { headers: { authtoken } }
  );
//GEO INFO
//DIVISION
export const axiosGetDivision = async (authtoken, currentPage, pageRange) =>
  await axios.get(`${REACT_APP_API}/division/${pageRange}/${currentPage}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const postDivisiondata = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/division`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateDivisionData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/division/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//DISTRICT
// export const axiosGetDistrict = async (authtoken, currentPage, pageRange) =>
//   await axios.get(`${REACT_APP_API}/district/${pageRange}/${currentPage}`, {
//     headers: { Authorization: `Bearer ${authtoken}` },
//   });
export const axiosGetDistrict = async (url, authtoken) =>
  await axios.get(url, { headers: { Authorization: `Bearer ${authtoken}` } });
export const postDistrictData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/district`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateDistrictData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/district/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//UPAZILA
export const axiosGetUpazila = async (url, authtoken) =>
  await axios.get(url, { headers: { Authorization: `Bearer ${authtoken}` } });
export const postUpazilaData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/upazila`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateUpazilaData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/upazila/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//filter
export const axiosGetDistrictByDivision = async (
  authtoken,
  id,
  currentPage,
  pageRange
) =>
  await axios.get(
    `${REACT_APP_API}/district/get-district-by-division/${id}/${pageRange}/${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
//PARLIAMENTARY
export const axiosGetParliamentary = async (url, authtoken) =>
  await axios.get(url, { headers: { Authorization: `Bearer ${authtoken}` } });
export const postParliamentaryData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/electoralSeat`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateParliamentaryData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/electoralSeat/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const addUserData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/users`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getUserData = async (authtoken, role, list, currentPage) =>
  await axios.get(
    `${REACT_APP_API}/users/user-list/${role}/${list}/${currentPage}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
//INSTITUTE
export const axiosGetSingleInstitute = async (authtoken, instituteId) =>
  await axios.get(`${REACT_APP_API}/institute/${instituteId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

export const userAssignInstitute = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/users/assign-institute`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const postInstitutenData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/institute`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateInstituteData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/institute/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getInstituteData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/institute/institute-list`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getInstituteBySOList = async (authtoken, role) =>
  await axios.get(`${REACT_APP_API}/users/get-institute-by-so/${role}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getIssueData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/issue/issue-list`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const getIssueDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/issue/issue-details/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const postRemarksData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/issue/issue-solving`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

export const getUserDetailsData = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/users/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//UPDATE, ADD ISSUE
export const postIssuenData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/issue`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updateIssueData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/issue/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

//EDIT USER
export const updateUserData = async (id, data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/users/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//RESET PASSWORD
export const resetPasswordData = async (data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/users/updateMyPassword`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

//search institute
export const getSearchInstituteData = async (authtoken, data) =>
  await axios.get(`${REACT_APP_API}/users/user-search?seachKeyword=${data}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
//search pjp
export const getSearchPjpData = async (authtoken, data) =>
  await axios.get(
    `${REACT_APP_API}/institute/institute-search?seachKeyword=${data}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

// Attendance
export const getAttendanceData = async (
  authtoken,
  lineManagerId,
  userId,
  fromDate,
  handleList,
  currentPage,
  status
) =>
  await axios.get(
    `${REACT_APP_API}/attendance/attendance-report?userId=${userId}&status=${status}&dateid=${fromDate}`,

    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

export const getAllAttendanceData = async (authtoken, fromDate, toDate) =>
  await axios.get(
    // `${REACT_APP_API}/attendance/attendance-report?userId=${userId}&status=${status}&dateid=${fromDate}`,

    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

export const getSingleAttendanceData = async (
  authtoken,
  fromDate,
  toDate,
  userId
) =>
  await axios.get(
    `${REACT_APP_API}/attendance/date-to-date-attendance-sammary?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

//PJP
export const axiosGetPjp = async (authtoken, laUserId, fromDate, toDate) =>
  await axios.get(
    `${REACT_APP_API}/pjp/get-pjp-by-user/${laUserId}/${fromDate}/${toDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const postPjpData = async (data, authtoken) =>
  await axios.post(`${REACT_APP_API}/pjp`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });
export const updatePjpData = async (data, authtoken) =>
  await axios.patch(`${REACT_APP_API}/pjp`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

// Dashboard

export const getIssueStatusData = async (authtoken, startDate, EndDate) =>
  await axios.get(
    `${REACT_APP_API}/dashboard/get-number-of-pending-solved-issue/${startDate}/${EndDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );

export const getPendingIssueBySOData = async (authtoken, startDate, EndDate) =>
  await axios.get(
    `${REACT_APP_API}/dashboard/top-pending-issues-by-so/${startDate}/${EndDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const getPendingIssueByInstituteData = async (
  authtoken,
  startDate,
  EndDate
) =>
  await axios.get(
    `${REACT_APP_API}/dashboard/top-pending-issues-by-institute/${startDate}/${EndDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  );
export const getDashBoardAttendanceData = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/dashboard/current-date-attendance`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

export const postData = async (data, authToken) =>
  await axios.post(`${REACT_APP_API}/institute/image-uplaod`, data, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const deleteData = async (authtoken, data) =>
  await axios.delete(`${REACT_APP_API}/institute/delete-image/${data}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  });

// {
//   console.log("call from here", ...data);
//   return post(url, data, {
//     headers: { Authorization: `Bearer ${authToken}` },
//   });
// };
